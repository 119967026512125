import { ControlPanel, NotificationArea, Timers } from 'question-base';
import { dbPut } from 'utils-db';
import { translate } from 'utils-lang';
import { isIndexed, mkNode } from './utils';
import { alertModal } from './utils-progress';

function isScheduleItem(x: unknown): x is PractiqueNet.ExamJson.Definitions.ScheduleItem {
    return isIndexed(x) &&
        typeof x.type === 'string' && x.type === 'item' &&
        typeof x.time === 'number' &&
        typeof x.item === 'number' &&
        (typeof x.duration === 'number' || typeof x.duration === "undefined");
}

function isScheduleMessage(x: unknown): x is PractiqueNet.ExamJson.Definitions.ScheduleMessage {
    return isIndexed(x) &&
        typeof x.time === 'number' &&
        (typeof x.duration === 'number' || typeof x.duration === "undefined") &&
        typeof x.type === 'string' && x.type === 'message' &&
        (typeof x.value  === 'string' || typeof x.duration === "undefined");
}

function isScheduleTimedMessage(x: unknown): x is PractiqueNet.ExamJson.Definitions.ScheduleTimedMessage {
    return isIndexed(x) &&
        typeof x.time === 'number' &&
        (typeof x.duration === 'number' || typeof x.duration === "undefined") &&
        typeof x.type === 'string' && x.type === 'timedMessage' &&
        (typeof x.value  === 'string' || typeof x.duration === "undefined");
}

function isConditionalMessage(x: unknown): x is PractiqueNet.ExamJson.Definitions.ScheduleConditionalMessage {
    return isIndexed(x) &&
        typeof x.time === 'number' &&
        (typeof x.duration === 'number' || typeof x.duration === "undefined") &&
        typeof x.type === 'string' && x.type === 'conditionalMessage' &&
        (typeof x.value  === 'string' || typeof x.duration === "undefined");
}

function isReadOnly(x: unknown): x is PractiqueNet.ExamJson.Definitions.ScheduleReadOnly {
    return isIndexed(x) &&
        typeof x.time === 'number' &&
        (typeof x.duration === 'number' || typeof x.duration === "undefined") &&
        typeof x.type === 'string' && x.type === 'read-only';
}

function isReadWrite(x: unknown): x is PractiqueNet.ExamJson.Definitions.ScheduleReadWrite {
    return isIndexed(x) &&
    typeof x.time === 'number' &&
    (typeof x.duration === 'number' || typeof x.duration === "undefined") &&
    typeof x.type === 'string' && x.type === 'read-write';
}

export function isSchedule(x: unknown): x is PractiqueNet.ExamJson.Definitions.Schedule {
    if (Array.isArray(x)) {
        let valid = true;
        for (const item of x) {
            if (!(isScheduleItem(item) || isScheduleMessage(item) || isScheduleTimedMessage(item) || isConditionalMessage(item) || isReadOnly(item) || isReadWrite(item))) {
                console.warn('Invalid schedule command', item);
                valid = false;
            }
        }
        return valid;
    }
    console.warn('Schedule is not an array');
    return false;
}

export interface VersionedSchedule {
    schedule: PractiqueNet.ExamJson.Definitions.Schedule,
    version: number;
}

export function isVersionedSchedule(x: unknown): x is VersionedSchedule {
    if (!isIndexed(x)) {
        console.warn('SavedSchedule is not an object');
        return false;
    }
    if (!isSchedule(x.schedule)) {
        return false;
    }
    if (typeof x.version !== 'number') {
        console.warn('SavedSchedule has no valid version');
        return false;
    }
    return true;
}

function formatTime(t: number): string {
    const n = Math.ceil(t) < 0;
    const h = Math.floor(Math.abs(t) / 3600);
    const m = Math.floor(Math.abs(t) / 60) - h * 60;
    const s = Math.floor(Math.abs(t)) - h * 3600 - m * 60;
    const H = String(h);
    return `${n ? '-':''}${H.length < 2 ? '0' + H : H}:${('0' + m).slice(-2)}:${('0' + s).slice(-2)}`;
}

export class ExamTimer implements Timers {
    private callback?: () => Promise<void>;
    private readonly controlPanel: ControlPanel;
    private readonly notificationArea: NotificationArea;
    private readonly timeText: HTMLSpanElement;
    private readonly timeLabel: HTMLSpanElement;
    private readonly timerElement: HTMLButtonElement;
    private readonly down: boolean;
    private readonly auto: boolean;
    private elapsedSeconds = 0;
    private currentTime = 0;
    private duration: number;
    private hasDuration = false;
    private startSeconds: number|null;
    private interval?: number;
    private schedule: VersionedSchedule;
    private isReadOnly = false;
    private timer: {[timer:string]: {enabled: boolean, startTime: number|null, savedTime: number}} = {};
    private language: number;

    private lang(primary: string, secondary?: string[]): string {
        return secondary?.[this.language - 1] ?? primary;
    }

    public update(): void {
        let readOnly = false;
        const messages: string[] = []
        let itemContext: number|undefined;
        let itemRemaining: number|undefined;
        for (const item of this.schedule.schedule) {
            switch (item.type) {
                case 'conditionalMessage': // move to START/STOP + 30 second checkpoint, with save.
                    if (itemContext !== undefined && itemContext !== this.notificationArea.getItem()) {
                        break;
                    }
                    if (!this.after(item.time)) {
                        const timer = this.timer[item.condition];
                        if (timer) {
                            let elapsedTime: number;
                            if (timer.startTime === null) {
                                if (timer.enabled) {
                                    timer.startTime = Date.now() / 1000;
                                }
                                elapsedTime = timer.savedTime;
                            } else {
                                elapsedTime = Date.now() / 1000 - timer.startTime + timer.savedTime;
                            }
                            const remaining = item.duration - elapsedTime;
                            if (remaining > 0) {
                                messages.push(`${this.lang(item.value, item.valueVariants)} ${formatTime(remaining)}`);
                            } else if (item.expired !== undefined) {
                                messages.push(this.lang(item.expired, item.expiredVariants));
                            }
                        }
                    }
                    break;
                case 'timedMessage': { // show scheduled message
                    if (itemContext !== undefined && itemContext !== this.notificationArea.getItem()) {
                        break;
                    }
                    const remaining = this.inRange(item.time, item.duration ?? 60);
                    if (remaining > 0) {
                        messages.push(`${this.lang(item.value, item.valueVariants)} ${formatTime(remaining + 1)}`);
                    }
                    break;
                }
                case 'message': {
                    if (itemContext !== undefined && itemContext !== this.notificationArea.getItem()) {
                        break;
                    }
                    const remaining = this.inRange(item.time, item.duration ?? 60);
                    if (item.value && remaining > 0) {
                        messages.push(this.lang(item.value, item.valueVariants));
                    }
                    break;
                }
                case 'item': { // restrict to selected item
                    const remaining = this.inRange(item.time, item.duration ?? 60);
                    //console.debug('ITEM', item.item, this.currentTime, '->', remaining);
                    if (item.item !== undefined) {
                        itemContext = item.item;
                        if (remaining > 0) {
                            itemRemaining = remaining + 1;
                            if (this.auto) {
                                this.notificationArea.setItem(itemContext); // async
                            }
                        }
                    }
                    break;
                }
                case 'read-only': { // restrict to read-only
                    if (itemContext !== undefined && itemContext !== this.notificationArea.getItem()) {
                        break;
                    }
                    const remaining = this.inRange(item.time, item.duration ?? 60);
                    if (remaining > 0) {
                        readOnly = true;
                    }
                    break;
                }
                case 'read-write':
                    break;
                default:
                    console.error('UNSUPPORTED SCHEDULE ITEM', item);
                    break;
            }
        }

        if (itemRemaining !== undefined) {
            this.timeText.textContent = formatTime(itemRemaining);
            this.timeLabel.textContent = translate('TIMER_ROUND_REMAINING');
        } else {
            this.notificationArea.setItem();
            this.timeText.textContent = formatTime(this.down ? this.duration - this.currentTime : this.currentTime);
            this.timeLabel.textContent = this.down ? translate('TIMER_REMAINING') : translate('TIMER_ELAPSED');
        }

        if (messages.length > 0) {
            this.notificationArea.show(messages.join('<br>'));
        } else {
            this.notificationArea.hide();
        }
        if (readOnly !== this.isReadOnly) {
            this.notificationArea.setReadOnly(readOnly);
            this.isReadOnly = readOnly;
        }
    }

    private inRange(time: number, duration: number): number {
        const t = (time < 0) ? this.currentTime - this.duration - time : time + duration - this.currentTime;
        return (t <= duration) ? t : duration - t + 1; //>= 0 ? duration - t : t; //0 <= t && t < duration;
    }

    private after(time: number): boolean {
        return 0 <= ((time < 0) ? this.currentTime - time : time - this.currentTime);
        //const t = (time < 0) ? this.currentTime - time : time - this.currentTime;
        //return t;
    }

    private readonly handleInterval = () => {
        this.currentTime = (this.startSeconds === null) ? this.elapsedSeconds : Date.now() / 1000 - this.startSeconds + this.elapsedSeconds;
        if (this.startSeconds !== null) {
            this.update();
        }
        if (this.callback && this.hasDuration && this.currentTime >= this.duration) {
            this.callback();
            this.hasDuration = false;
        }
    }

    private getDuration(): number {
        this.hasDuration = false;
        let duration = 0;
        for (const item of this.schedule.schedule) {
            switch (item.type) {
                case 'read-only':
                case 'read-write':
                case 'item':
                    duration += item.duration ?? 60;
                    this.hasDuration = true;
                    break;
                default:
                    break;
            }
        }
        return duration;
    }

    constructor({controlPanel, notificationArea, timing, schedule, callback, language}: {
        controlPanel: ControlPanel,
        notificationArea: NotificationArea,
        timing?: PractiqueNet.ExamJson.Definitions.Timing,
        schedule: VersionedSchedule,
        callback: () => Promise<void>,
        language: number;
    }) {
        console.debug('TIMING', timing);
        this.controlPanel = controlPanel;
        this.notificationArea = notificationArea;
        this.callback = callback;
        this.timeText = mkNode('span', {className: 'app-text'});
        this.timeLabel = mkNode('span', {className: 'app-button-text'});
        this.timerElement = mkNode('button', {
            className: 'app-button',
            attrib: {disabled: 'true', hidden: 'true'},
            children: [this.timeText, this.timeLabel],
        });
        this.down = timing ? timing.counter === 'down' : false;
        this.auto = timing ? timing.rotation === 'auto' : false;
        this.startSeconds = null;
        this.schedule = schedule;
        this.duration = this.getDuration();
        //this.update();
        this.controlPanel.add(this.timerElement);
        this.language = language;
    }

    public setLanguage(language: number) {
        this.language = language;
    }

    public getElapsed(): number {
        if (this.startSeconds === null) {
            return this.elapsedSeconds;
        } else {
            return Date.now() / 1000 - this.startSeconds + this.elapsedSeconds;
        }
    }

    public finished(): boolean {
        return this.duration > 0 && this.currentTime >= this.duration;
    }

    public setElapsed(seconds: number, timestampMilli?: number): void {
        const offset = (timestampMilli === undefined) ? 0 : Date.now() - timestampMilli;
        console.debug(`ELAPSED <- ${seconds}s + ${offset}ms`);
        this.elapsedSeconds = seconds + offset / 1000;
        this.startSeconds = null;
        this.currentTime = this.elapsedSeconds;
        if (this.startSeconds !== null) {
            this.update();
        }
    }

    public async setSchedule(schedule: VersionedSchedule): Promise<void> {
        try {
            console.log(`SCHEDULE <- ${schedule.version}`);
            this.schedule = schedule;
            this.duration = this.getDuration();
            await dbPut('users', 'schedule', schedule);
            if (this.startSeconds !== null) {
                this.update();
            }
        } catch (err) {
            console.error('SCHEDULE', String(err));
            alertModal(`Schedule update failed: ${String(err)}`);
        }
    }

    public getScheduleVersion(): number {
        return this.schedule.version;
    }

    public start(timer?: string): boolean {
        if (timer === undefined) {
            if (!this.interval) {
                this.interval = window.setInterval(this.handleInterval, 1000);
            }
            if (this.startSeconds === null) {
                this.timerElement.hidden = false;
                this.startSeconds = Date.now() / 1000;
                this.currentTime = this.elapsedSeconds;
                this.update();
                return true;
            } else {
                this.currentTime = Date.now() / 1000 - this.startSeconds + this.elapsedSeconds;
                this.update();
            }
        } else {
            if (this.timer[timer] !== undefined) {
                if (!this.timer[timer].enabled) {
                    this.timer[timer].enabled = true;
                    return true;
                }
            } else {
                this.timer[timer] = {enabled: true, startTime: null, savedTime: 0};
                return true;
            }
        }
        return false;
    }

    public stop(timer?: string): boolean {
        if (timer === undefined) {
            if (this.interval) {
                window.clearInterval(this.interval);
                this.interval = undefined;
            }
            if (this.startSeconds !== null) {
                this.elapsedSeconds += Date.now() / 1000 - this.startSeconds;
                this.startSeconds = null;
                this.currentTime = this.elapsedSeconds;
                this.update();
                return true;
            } else {
                this.currentTime = this.elapsedSeconds;
                //this.update(); /* DOES THIS CAUSE ANY PROBLEMS */
                return false;
            }
        } else {
            if (this.timer[timer] !== undefined) {
                if (this.timer[timer].enabled) {
                    this.timer[timer].enabled = false;
                    const {startTime, savedTime} = this.timer[timer];
                    if (startTime !== null) {
                        this.timer[timer].savedTime = Date.now() / 1000 - startTime + savedTime;
                        this.timer[timer].startTime = null;
                    }
                    return true;
                }
            }
        }
        return false;
    }

    public set(timer: string, time: number): void {
        console.debug(`TIMER ${timer} <- ${time}`);
        if (this.timer[timer]) {
            this.timer[timer].savedTime = time;
            if (this.timer[timer].startTime !== null) {
                this.timer[timer].startTime = Date.now() / 1000;
            }
        } else {
            this.timer[timer] = {enabled: false, startTime: null, savedTime: time};
        }
    }

    public get(timer?: string): number {
        if (timer === undefined) {
            if (this.startSeconds === null) {
                return this.elapsedSeconds;
            } else {
                return Date.now() / 1000 - this.startSeconds + this.elapsedSeconds;
            }
        }
        if (this.timer[timer] === undefined) {
            console.debug(`TIMER ${timer} -> 0`);
            return 0;
        }
        const {enabled, startTime, savedTime} = this.timer[timer];
        if (startTime !== null) {
            const now = Date.now() / 1000;
            const time = now - startTime + savedTime;
            this.timer[timer] = {enabled, startTime: now, savedTime: time};
            console.debug(`TIMER ${timer} -> ${time}`);
            return time;
        } else {
            console.debug(`TIMER ${timer} -> ${savedTime}`);
            return savedTime;
        }
    }

    public async destroy(): Promise<void> {
        this.controlPanel.remove(this.timerElement);
        this.callback = undefined;
    }
}