
declare global {
    interface Window {
        webkitIndexedDB?: typeof window.indexedDB;
        mozIndexedDB?: typeof window.indexedDB;
        oIndexedDB?: typeof window.indexedDB;
        msIndexedDB?: typeof window.indexedDB;
        shimIndexedDB?: typeof window.indexedDB;
    }
}

const IDB = window.indexedDB || window.webkitIndexedDB || window.mozIndexedDB ||
    window.oIndexedDB || window.msIndexedDB || window.shimIndexedDB;

if (IDB === window.shimIndexedDB) {
    console.warn('WARNING: using IndexedDBShim');
}

export class StorageError extends Error {}

export function toStorageError(x: unknown): StorageError {
    const err = (x instanceof Error) ? x : new Error(String(x));
    err.name = 'StorageError';
    Object.setPrototypeOf(err, StorageError.prototype);
    return err;
}

let db: IDBDatabase | undefined;

export async function openDatabase(): Promise<IDBDatabase> {
    if (db) {
        return db;
    }
    return new Promise((resolve, reject): void => {
        try {
            const openRequest = IDB.open('practique', 8);
            openRequest.onblocked = (): void => {
                console.error('open database blocked:', String(openRequest.error));
                reject(toStorageError(openRequest.error));
            };
            openRequest.onerror = (): void => {
                console.error('open database error: ', String(openRequest.error));
                reject(toStorageError(openRequest.error));
            };
            openRequest.onupgradeneeded = (event: IDBVersionChangeEvent): void => {
                console.log('upgrade needed');
                if (event.newVersion != null && event.oldVersion != event.newVersion) {
                    console.log('recreating database');
                    const db = openRequest.result;
                    if (db.objectStoreNames.contains('exams')) {
                        db.deleteObjectStore("exams");
                    }
                    if (db.objectStoreNames.contains('encrypted')) {
                        db.deleteObjectStore("encrypted");
                    }
                    if (db.objectStoreNames.contains('questions')) {
                        db.deleteObjectStore('questions');
                    }
                    if (db.objectStoreNames.contains('images')) {
                        db.deleteObjectStore('images');
                    }
                    if (db.objectStoreNames.contains('answers')) {
                        db.deleteObjectStore('answers');
                    }
                    if (db.objectStoreNames.contains('status')) {
                        db.deleteObjectStore('status');
                    }
                    if (db.objectStoreNames.contains('flags')) {
                        db.deleteObjectStore('flags');
                    }
                    if (db.objectStoreNames.contains('users')) {
                        db.deleteObjectStore('users');
                    }
                    if (db.objectStoreNames.contains('session')) {
                        db.deleteObjectStore('session');
                    }
                    if (db.objectStoreNames.contains('notes')) {
                        db.deleteObjectStore('notes');
                    }
                    db.createObjectStore('exams');
                    db.createObjectStore('encrypted');
                    db.createObjectStore('questions');
                    db.createObjectStore('images');
                    db.createObjectStore('answers');
                    db.createObjectStore('status');
                    db.createObjectStore('flags');
                    db.createObjectStore('users');
                    db.createObjectStore('session');
                    db.createObjectStore('notes');
                }
            };
            openRequest.onsuccess = (): void => {
                db = openRequest.result;
                db.onversionchange = () => {
                    console.log('DB_VERSION_CHANGE');
                    if (db) {
                        db.close();
                        db = undefined;
                    }
                }
                db.onclose = () => {
                    console.log('DB_CLOSED');
                    db = undefined;
                }
                resolve(db);
            };
        } catch (err) {
            console.error('error: openDatabase', String(err));
            reject(toStorageError(err));
        }
    });
}

export async function dbGet<T = unknown>(store: string, key: IDBValidKey): Promise<T | undefined> {
    const db = await openDatabase();
    return new Promise<T>((resolve, reject): void => {
        const request = db.transaction([store], "readonly").objectStore(store).get(key);
        request.onerror = () => reject(toStorageError(request.error));
        request.onsuccess = () => resolve(request.result);
    });
}

/*
export async function dbGetList<K extends IDBValidKey, T = unknown>(storeName: string, keys: K[], guard: (x: unknown) => x is T): Promise<{key: K, value:T}[]> {
    const db = await openDatabase();
    return new Promise((resolve, reject): void => {
        const results: {key: K, value:T}[] = [];
        const transaction = db.transaction([storeName], "readonly");
        const store = transaction.objectStore(storeName);
        transaction.onabort = () => reject(toStorageError(transaction.error));
        transaction.onerror = () => reject(toStorageError(transaction.error));
        transaction.oncomplete = () => resolve(results);
        let i = 0;
        let request: IDBRequest<unknown>;
        function next() {
            if (request) {
                if (guard(request.result)) {
                    results.push({key: keys[i], value: request.result});
                }
                ++i;
            }
            if (i < keys.length) {
                request = store.get(keys[i]);
                request.onsuccess = next;
            }
        }
        next();
    });
}
*/

export async function dbCursor(store: string, key: undefined | IDBValidKey | IDBKeyRange,
    fn: (cursor: IDBCursorWithValue) => void
): Promise<void> {
    const db = await openDatabase();
    return new Promise<void>((resolve, reject): void => {
        const request = db.transaction([store], "readonly").objectStore(store).openCursor(key);
        request.onerror = () => reject(toStorageError(request.error));
        request.onsuccess = () => {
            const cursor = request.result;
            if (cursor) {
                try {
                    fn(cursor);
                    cursor['continue']();
                } catch (err) {
                    reject(toStorageError(err));
                }
            } else {
                resolve();
            }
        };
    });
}

export async function dbPut<T>(store: string, key: IDBValidKey, value: T): Promise<void> {
    const db = await openDatabase();
    return new Promise<void>(async (resolve, reject): Promise<void> => {
        const transaction = db.transaction([store], "readwrite");
        transaction.objectStore(store).put(value, key);
        transaction.onabort = () => reject(toStorageError(transaction.error));
        transaction.onerror = () => reject(toStorageError(transaction.error));
        transaction.oncomplete = () => resolve();
    });
}

export async function dbPutList<T>(store: string, list: {key: IDBValidKey, value: T}[]): Promise<void> {
    const db = await openDatabase();
    return new Promise<void>((resolve, reject) => {
        const transaction = db.transaction([store], "readwrite");
        transaction.onabort = () => reject(toStorageError(transaction.error));
        transaction.onerror = () => reject(toStorageError(transaction.error));
        transaction.oncomplete = () => resolve();
        let i = 0;
        const next = () => {
            if (i < list.length) {
                const request = transaction.objectStore(store).put(list[i].value, list[i].key);
                request.onsuccess = next;
                ++i;
            }
        }
        next();
    });
}

export async function dbDelete(store: string, key: IDBValidKey | IDBKeyRange): Promise<void> {
    const db = await openDatabase();
    return new Promise<void>((resolve, reject): void => {
        const transaction = db.transaction([store], "readwrite");
        transaction.objectStore(store).delete(key);
        transaction.onabort = () => reject(toStorageError(transaction.error));
        transaction.onerror = () => reject(toStorageError(transaction.error));
        transaction.oncomplete = () => resolve();
    });
}

export async function dbClear(store: string): Promise<void> {
    const db = await openDatabase();
    return new Promise<void>((resolve, reject): void => {
        const transaction = db.transaction([store], "readwrite");
        transaction.objectStore(store).clear();
        transaction.onabort = () => reject(toStorageError(transaction.error));
        transaction.onerror = () => reject(toStorageError(transaction.error));
        transaction.oncomplete = () => resolve();
    });
}

export async function dbClearLogin(): Promise<void> {
    console.log("DELETE_ALL_BEGIN");
    const t0 = performance.now();
    await dbClear('exams');
    await dbClear('encrypted');
    await dbClear('questions');
    await dbClear('images');
    await dbClear('answers');
    await dbClear('status');
    await dbClear('flags');
    await dbClear('users');
    await dbClear('notes');
    const t1 = performance.now();
    console.log(`DELETE_ALL_END ${(t1 - t0)/1000}`);
}

export async function dbClearSelect(): Promise<void> {
    console.log("DELETE_EXAM_BEGIN");
    const t0 = performance.now();
    await dbClear('questions');
    await dbClear('images');
    await dbClear('answers');
    await dbClear('status');
    await dbClear('flags');
    await dbClear('notes');
    await dbDelete('users', 'language');
    await dbDelete('users', 'question');
    await dbDelete('users', 'state');
    await dbDelete('users', 'schedule');
    await dbDelete('users', 'scheduleVersion');
    await dbDelete('users', 'manifest');
    await dbDelete('users', 'exam');
    const t1 = performance.now();
    console.log(`DELETE_EXAM_END ${(t1 - t0)/1000}`);
}
