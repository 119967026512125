import { mkNode, removeNode } from './utils';
import { faPalette, faSquare } from '@fortawesome/free-solid-svg-icons';
import { ControlPanel } from './question-base';
import { translate } from 'utils-lang';
import ResizeObserver from 'resize-observer-polyfill';
import 'pepjs';


//----------------------------------------------------------------------------------------
// Global style management

const dstyle = document.createElement('style');
document.body.appendChild(dstyle);
const classIndex: {[index: string]: number} = {};
let rule = 0;

export function setStyle(className: string, styles: Partial<CSSStyleDeclaration>): void {
    if (dstyle.sheet instanceof CSSStyleSheet) {
        let cssRule;
        if (!(className in classIndex)) {
            classIndex[className] = rule;
            dstyle.sheet.insertRule(`.${className} {}`, rule++);
            cssRule = dstyle.sheet.cssRules[rule - 1];
        } else {
            cssRule = dstyle.sheet.cssRules[classIndex[className]];
        }
        if (cssRule instanceof CSSStyleRule) {
            for (const x in styles) {
                const style = styles[x];
                if (style !== undefined) {
                    cssRule.style[x] = style;
                }
            }
        }
    }
}


//-----------------------------------------------------------------------------------
// Custom slider control

class Slider {
    private readonly canvas: HTMLCanvasElement;
    private readonly ondraw: (context: CanvasRenderingContext2D, x: number) => void;
    private readonly ondone: () => void;
    private readonly resizeObserver: ResizeObserver;
    private value: number;
    private isDown = false;

    private readonly down = (event: PointerEvent) => {
        this.canvas.setPointerCapture(event.pointerId);
        const {left, width} = this.canvas.getBoundingClientRect();
        this.setAndDraw((event.clientX - left) / width);
        this.isDown = true;
    }

    private readonly move = (event: PointerEvent) => {
        if (this.isDown) {
            const {left, width} = this.canvas.getBoundingClientRect();
            this.setAndDraw((event.clientX - left) / width);
        }
    }

    private readonly up = () => {
        this.isDown = false;
    }

    private readonly cancel = () => {
        this.isDown = false;
    }

    private readonly resize = (entries: ResizeObserverEntry[]): void => {
        const l = entries.length;
        if (l > 0) {
            const dpr = window.devicePixelRatio || 1;
            const {width, height} = entries[l-1].contentRect;
            this.canvas.width = dpr * width;
            this.canvas.height = dpr * height;
            this.draw();
        }
    }

    private timestamp?: number;

    private readonly frame = (timestamp: number): void => {
        if (timestamp !== this.timestamp) {
            this.timestamp = timestamp;
            const context = this.canvas.getContext('2d');
            if (context) {
                const dpr = 2 * (window.devicePixelRatio || 1);
                context.lineWidth = dpr;
                this.ondraw(context, this.value);
            }
        }
    }

    private readonly frameWithCallback = (timestamp: number): void => {
        this.frame(timestamp);
        this.ondone();
    }

    private setAndDraw(value: number): void {
        if (this.value !== value) {
            this.set(value);
            requestAnimationFrame(this.frameWithCallback);
        }
    }

    constructor(id: string, parent: Element, value: number, ondraw: (context: CanvasRenderingContext2D, value: number) => void, ondone: () => void) {
        this.canvas = mkNode('canvas', {id, className: 'slider-canvas', parent, attrib: {
            'touch-action': 'none',
        }});
        this.value = value;
        this.ondraw = ondraw;
        this.ondone = ondone;
        this.resizeObserver = new ResizeObserver(this.resize);
        this.resizeObserver.observe(this.canvas);
        this.canvas.addEventListener('pointerdown', this.down);
        this.canvas.addEventListener('pointermove', this.move);
        this.canvas.addEventListener('pointerup', this.up);
        this.canvas.addEventListener('pointercancel', this.cancel);
    }

    public destroy() {
        this.resizeObserver.disconnect();
        this.canvas.removeEventListener('pointerdown', this.down);
        this.canvas.removeEventListener('pointermove', this.move);
        this.canvas.removeEventListener('pointerup', this.up);
        this.canvas.removeEventListener('pointercancel', this.cancel);
    }

    public set(value: number) {
        if (value < 0) {
            value = 0;
        } else if (value > 1) {
            value = 1;
        }
        this.value = value;
    }

    public draw() {
        requestAnimationFrame(this.frame);
    }
}


//---------------------------------------------------------------------------------------------------
// HC*L* Colour Model - L* is directly comparable to W3C contrast-ratio.
//
// W3C contrast-ratio applies inverseGamma, calculates relative-luminance, and then calculates contrast ratio.
// This colour model applies inverseGamma, then transforms from RGB to YUV. By using the BT.709 colourspace the
// Y component is exactly the W3C relative-luminance, and then scaling to perceptual lightness (L*) creates a scale
// where the contrast-ratio between two colours can be found simply be the absolute value of the difference in the
// colours' L* component.

type HCL = {hue: number, chr: number, lum: number};
type RGB = {red: number, green: number, blue: number, alpha?: number};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isHcl(x: any): x is HCL {
    return x && typeof x === 'object'
        && typeof x.hue === 'number'
        && typeof x.chr === 'number'
        && typeof x.lum === 'number';
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isRgb(x: any): x is RGB {
    return x && typeof x === 'object'
        && typeof x.red === 'number'
        && typeof x.green === 'number'
        && typeof x.blue === 'number';
}

function toString({red, green, blue, alpha = 1}: RGB) {
    if (alpha < 1) {
        return `rgba(${red >> 0},${green >> 0},${blue >> 0},${alpha})`;
    } else {
        return `rgb(${red >> 0},${green >> 0},${blue >> 0})`;
    }
}

function fmod(x: number, y: number): number {
    const z = x - Math.floor(x / y) * y
    return (z < 0) ? z + y : z;
}

// sRGB Gamma Companding

function inverseGamma(v: number): number {
    v /= 255;
    return  (v <= 0.04045) ? (v / 12.92) : ((v + 0.055) / 1.055) ** 2.4;
}

function applyGamma(w: number): number {
    //return 255 * ((w <= 0.0031308) ? (w * 12.92) : (1.055 * w ** (1 / 2.4) - 0.055));
    return Math.round(Math.max(Math.min(255 * ((w <= 0.0031308) ? (w * 12.92) : (1.055 * w ** (1 / 2.4) - 0.055)), 255), 0));
}

// Normalised C*/Y* Perceptial Companding

const Y1 = 216 / 24389;
const Y2 = 24389 / 2700;
function toStar(y: number): number {
    if (y <= Y1) {
        return Y2 * y;
    } else {
        return (y ** (1/3) * 1.16 - 0.16);
    }
}

function fromStar(l: number): number {
    if (l > 0.08) {
        return ((l + 0.16) / 1.16) ** 3;
    } else {
        return l / Y2;
    }
}

// Get distance to unit square for polar angle for chroma UV scaling.
function unitSquareDistanceForAngle(angle: number): number {
    return 1 / Math.cos(((angle + 45) % 90 - 45) / 180 * Math.PI);
}

// BT.709
const Kr = 0.2126729;
const Kb = 0.0721750;
const Kg = 1 - Kb - Kr;
const a1 = 0;
const b1 = 1 - Kr;
const a2 = -(Kb / Kg) * (1 - Kb);
const b2 = -(Kr / Kg) * (1 - Kr);
const a3 = 1 - Kb;
const b3 = 0;

function hclToRgb({hue, chr, lum}: HCL): RGB {
    // convert perceptial lightness to luma
    const Y = fromStar(lum);
    // convert polar to UV
    const h = fmod(hue + 102.91012733297319, 360) * Math.PI / 180;
    const C = fromStar(chr) * unitSquareDistanceForAngle(h);
    let u = C * Math.cos(h);
    let v = C * Math.sin(h);
    // YUV to RGB luminance preserving clipping
    const uv1 = a1 * u + b1 * v;
    const uv2 = a2 * u + b2 * v;
    const uv3 = a3 * u + b3 * v;
    const c = Math.max(uv1, uv2, uv3);
    if (Y + c > 1) {
        const s = (1 - Y) / c;
        u = s * u;
        v = s * v;
    }
    const d = Math.min(uv1, uv2, uv3);
    if (Y + d < 0) {
        const s = (0 - Y) / d;
        u = s * u;
        v = s * v;
    }
    // convert YUV to sRGB
    return {
        red: applyGamma(Y + a1 * u +b1 * v),
        green: applyGamma(Y + a2 * u + b2 * v),
        blue: applyGamma(Y + a3 * u + b3 * v),
    };
}

const Krb = Kr / (1 - Kb);
const Kgb = Kg / (1 - Kb);
const Kgr = Kg / (1 - Kr);
const Kbr = Kb / (1 - Kr);

function rgbToHcl({red, green, blue}: RGB): HCL {
    const r = inverseGamma(red), g = inverseGamma(green), b = inverseGamma(blue);
    const Y = Kr * r + Kg * g + Kb * b;
    const Pb = b - Krb * r - Kgb * g;
    const Pr = r - Kgr * g - Kbr * b;
    const hue = fmod(Math.atan2(Pr, Pb) * 180 / Math.PI - 102.91012733297319, 360);
    const chr = Math.sqrt(Pb * Pb + Pr * Pr) / unitSquareDistanceForAngle(hue);
    return {hue, chr: Math.max(Math.min(toStar(chr), 1), 0), lum: Math.max(Math.min(toStar(Y), 1), 0)};
}

function toHcl(colour: RGB | HCL): HCL {
    if (isRgb(colour)) {
        return rgbToHcl(colour);
    } else if (isHcl(colour)) {
        return {...colour};
    } else {
        throw new TypeError('Expected RGB or HCL colour');
    }
}

//---------------------------------------------------------------------------------------
// Colour Controls.

const black = {red: 0, green: 0, blue: 0};
const white = {red: 255, green: 255, blue: 255};
const qing = {hue: 215, chr: 0.349, lum: 0.312};
const safe = {hue: 134, chr: 0.403, lum: 0.391};
const warn = {hue: 42, chr: 0.817, lum: 0.838};
const dngr = {hue: 0, chr: 0.752, lum: 0.387};
const ntrl = {hue: 0, chr: 0, lum: 0.4};

function complementBg(base: HCL, theme: HCL): HCL {
    return {
        hue: (theme.hue + 180) % 360,
        chr: (1 - base.chr) * (1 - theme.chr) + base.chr * theme.chr,
        lum: base.lum * theme.lum + (base.lum - 0.1) * (1 - theme.lum),
    };
}

function complementFg(base: HCL, theme: HCL): HCL {
    return {
        hue: (theme.hue + 180) % 360,
        chr: (1 - base.chr) * (1 - theme.chr) + base.chr * theme.chr,
        lum: (base.lum + 0.1) * (1 - theme.lum) + base.lum * theme.lum,
    };
}

function background(base: HCL, theme: HCL): HCL {
    return {
        hue: base.hue,
        chr: base.chr,
        lum: (theme.lum < 0.5) ?
            Math.max(base.lum, theme.lum + 0.25) :
            Math.min(base.lum, theme.lum - 0.25),
        };
}

function foreground(base: HCL, theme: HCL): HCL {
    return {
        hue: base.hue,
        chr: base.chr,
        lum: (theme.lum < 0.5) ?
            Math.max(base.lum, theme.lum + 0.25) :
            Math.min(base.lum, theme.lum - 0.25),
    };
}

const overlays = [
    {name: 'white', background: {hue: 35, chr: 0, lum: 1}},
    {name: 'yellow', background: {red: 197, green: 168, blue: 0}},
    {name: 'orange', background: {red: 217, green: 117, blue: 44}},
    {name: 'rose', background: {red: 212, green: 108, blue: 123}},
    {name: 'pink', background: {red: 184, green: 111, blue: 168}},
    {name: 'purple', background: {red: 128, green: 118, blue: 191}},
    {name: 'blue', background: {red: 64, green: 138, blue: 191}},
    {name: 'aqua', background: {red: 35, green: 156, blue: 174}},
    {name: 'lime', background: {red: 112, green: 150, blue: 6}},
    {name: 'mint', background: {red: 26, green: 168, blue: 105}},
    {name: 'grey', background: {red: 131, green: 128, blue: 123}},
    {name: 'dark', background: {hue: 35, chr: 0, lum: 0}},
];

function setAppBackground(hcl: HCL): void {
    const direction = hcl.lum > 0.5 ? -1 : 1;
    const bodyL0 = toString(hclToRgb(hcl));
    const bodyL1 = toString(hclToRgb({hue: hcl.hue, chr: hcl.chr, lum: hcl.lum + 0.05 * direction}));
    const bodyL2 = toString(hclToRgb({hue: hcl.hue, chr: hcl.chr, lum: hcl.lum + 0.10 * direction}));
    const bodyL3 = toString(hclToRgb({hue: hcl.hue, chr: hcl.chr, lum: hcl.lum + 0.15 * direction}));
    const bodyL4 = toString(hclToRgb({hue: hcl.hue, chr: hcl.chr, lum: hcl.lum + 0.20 * direction}));
    const bodyText = toString((hcl.lum > 0.5) ? black : white);
    const invText = toString((hcl.lum > 0.5) ? white : black);

    const headMix = complementBg(qing, hcl);
    const headL0 = toString(hclToRgb(headMix));
    const headL1 = toString(hclToRgb({hue: headMix.hue, chr: headMix.chr, lum: headMix.lum - 0.05}));
    const headL2 = toString(hclToRgb({hue: headMix.hue, chr: headMix.chr, lum: headMix.lum - 0.10}));
    const headL3 = toString(hclToRgb({hue: headMix.hue, chr: headMix.chr, lum: headMix.lum - 0.15}));
    const headText = toString((headMix.lum > 0.5) ? black : white);
    const headLine = toString(hclToRgb(complementFg(qing, hcl)));

    const safeBg = background(safe, hcl);
    const safeBg0 = toString(hclToRgb(safeBg));
    const safeBg1 = toString(hclToRgb({hue: safeBg.hue, chr: safeBg.chr, lum: safeBg.lum - 0.10}));
    const safeFg = foreground(safe, hcl);
    const safeFg0 = toString(hclToRgb(safeFg));
    const safeText = toString((safeBg.lum > 0.5) ? black : white);

    const warnBg = background(warn, hcl);
    const warnL0 = toString(hclToRgb(warnBg));
    const warnL1 = toString(hclToRgb({hue: warnBg.hue, chr: warnBg.chr, lum: warnBg.lum - 0.10}));
    const warnFg = foreground(warn, hcl);
    const warnFg0 = toString(hclToRgb(warnFg));
    const warnText = toString((warnBg.lum > 0.5) ? black : white);

    const dngrBg = background(dngr, hcl);
    const dngrL0 = toString(hclToRgb(dngrBg));
    const dngrL1 = toString(hclToRgb({hue: dngrBg.hue, chr: dngrBg.chr, lum: dngrBg.lum - 0.10}));
    const dngrFg = foreground(dngr, hcl);
    const dngrFg0 = toString(hclToRgb(dngrFg));
    const dngrText = toString((dngrBg.lum > 0.5) ? black : white);

    const ntrlBg = background(ntrl, hcl);
    const ntrlL0 = toString(hclToRgb(ntrlBg));
    const ntrlL1 = toString(hclToRgb({hue: ntrlBg.hue, chr: ntrlBg.chr, lum: ntrlBg.lum - 0.10}));
    //const ntrlFg = foreground(ntrl, hcl);
    //const ntrlFg0 = toString(hclToRgb(ntrlFg));
    const ntrlText = toString((ntrlBg.lum > 0.5) ? black : white);

    setStyle('config-background', {backgroundColor: bodyL0, color: bodyText, borderColor: bodyL2});
    setStyle('config-background-text', {color: bodyText});
    setStyle('config-background-hover:hover', {backgroundColor: bodyL2});
    setStyle('config-background-highlight-faint', {backgroundColor: bodyL1, color: bodyText,borderColor: bodyL3});
    setStyle('config-background-highlight', {backgroundColor: bodyL2, color: bodyText, borderColor: bodyL4});
    setStyle('config-background-highlight-hover:hover', {backgroundColor: bodyL4, color: bodyText});
    setStyle('config-body-border', {borderColor: bodyL0});
    setStyle('config-body-border-highlight', {borderColor: bodyL2})
    setStyle('config-body-border-focus:focus', {borderColor: bodyL0});
    setStyle('config-body-fg-border', {borderColor: bodyText});
    setStyle('config-body-fg-shadow-focus:focus', {boxShadow: `0 0 0 2px ${bodyText}`});
    setStyle('config-body-fg-light-border', {borderColor: toString((hcl.lum > 0.5) ? {...black, alpha: 0.5} : {...white, alpha: 0.5})});
    setStyle('config-body-fg-light-background', {backgroundColor: toString((hcl.lum > 0.5) ? {...black, alpha: 0.5} : {...white, alpha: 0.5})});
    setStyle('config-body-fg-light-background-text', {color: invText});
    setStyle('config-body-fg-light-background-hover:hover', {backgroundColor: toString((hcl.lum > 0.5) ? {...black, alpha: 0.75} : {...white, alpha: 0.75})});


    setStyle('config-primary', {backgroundColor: headL0, color: headText});
    setStyle('config-primary-hover:hover', {backgroundColor: headL1});
    setStyle('config-primary-border', {borderColor: headL0});
    setStyle('config-primary-shadow-focus:focus', {boxShadow: `0 0 0 2px ${headL0}`});
    setStyle('config-primary-border-focus:focus', {boxShadow: `0 0 0 2px ${headL0}`});
    setStyle('config-primary-border-empty', {borderColor: headLine});
    setStyle('config-primary-border-empty-focus:focus', {borderColor: headLine});
    setStyle('config-primary-border-empty-focus:focus-within', {borderColor: headLine});
    setStyle('config-primary-shadow-empty-focus:focus', {boxShadow: `0 0 0 2px ${headLine}`})
    setStyle('config-primary-shadow-empty-focus:focus-within', {boxShadow: `0 0 0 2px ${headLine}`})
    setStyle('config-primary-fg-border', {borderColor: headText});
    setStyle('config-primary-fg-border-focus:focus', {borderColor: headText});
    setStyle('config-primary-fg-shadow-focus:focus', {boxShadow: `0 0 0 2px ${headText}`});
    setStyle('config-primary-pressed[aria-pressed="true"]', {backgroundColor: headL2, color: headText});
    setStyle('config-primary-border-pressed[aria-pressed="true"]', {borderColor: headL2});
    setStyle('config-primary-border-pressed-focus[aria-pressed="true"]:focus', {borderColor: headText});
    setStyle('config-primary-pressed-hover[aria-pressed="true"]:hover', {background: headL3});
    setStyle('config-primary-pressed-true', {backgroundColor: headL2});
    setStyle('config-primary-pressed-true:hover', {backgroundColor: headL3});

    setStyle('config-safe', {backgroundColor: safeBg0, color: safeText});
    setStyle('config-safe-hover:hover', {backgroundColor: safeBg1});
    setStyle('config-safe-shadow-focus:focus', {boxShadow: `0 0 0 2px ${safeBg0}`});
    setStyle('config-safe-border-empty', {borderColor: safeFg0});
    setStyle('config-safe-shadow-empty-focus:focus', {boxShadow: `0 0 0 2px ${safeFg0}`});
    setStyle('config-safe-fg-border', {borderColor: safeText});
    setStyle('config-safe-fg-border-focus:focus', {borderColor: safeText});
    setStyle('config-safe-fg-shadow-focus:focus', {boxShadow: `0 0 0 2px ${safeText}`});
    setStyle('config-safe-pressed[aria-pressed="true"]', {backgroundColor: safeBg0, color: safeText});
    setStyle('config-safe-border-pressed[aria-pressed="true"]', {borderColor: safeBg0});
    setStyle('config-safe-border-pressed-focus[aria-pressed="true"]:focus', {borderColor: safeText});
    setStyle('config-safe-pressed-hover[aria-pressed="true"]:hover', {backgroundColor: safeBg1});
    setStyle('config-safe-text', {color: safeFg0});

    setStyle('config-warn', {backgroundColor: warnL0, color: warnText});
    setStyle('config-warn-hover:hover', {backgroundColor: warnL1});
    setStyle('config-warn-shadow-focus:focus', {boxShadow: `0 0 0 2px ${warnL0}`});
    setStyle('config-warn-fg-border', {borderColor: warnText});
    setStyle('config-warn-fg-border-focus:focus', {borderColor: warnText});
    setStyle('config-warn-fg-shadow-focus:focus', {boxShadow: `0 0 0 2px ${warnText}`});

    setStyle('config-warn-invalid[aria-invalid="true"]', {backgroundColor: warnL0, color: warnText});
    setStyle('config-warn-invalid-hover[aria-invalid="true"]:hover', {backgroundColor: warnL1});
    setStyle('config-warn-border-empty-invalid[aria-invalid="true"]', {borderColor: warnFg0});
    setStyle('config-warn-fg-border-invalid-focus[aria-invalid="true"]:focus', {borderColor: warnText});

    setStyle('config-dngr', {backgroundColor: dngrL0, color: dngrText});
    setStyle('config-dngr-fg', {color: dngrFg0});
    setStyle('config-dngr-hover:hover', {backgroundColor: dngrL1});
    setStyle('config-dngr-shadow-focus:focus', {boxShadow: `0 0 0 2px ${dngrL0}`});
    setStyle('config-dngr-border-empty', {borderColor: dngrFg0});
    setStyle('config-dngr-shadow-empty-focus:focus', {boxShadow: `0 0 0 2px ${dngrFg0}`});
    setStyle('config-dngr-fg-border', {borderColor: dngrText});
    setStyle('config-dngr-fg-border-focus:focus', {borderColor: dngrText});
    setStyle('config-dngr-fg-shadow-focus:focus', {boxShadow: `0 0 0 2px ${dngrText}`});
    setStyle('config-dngr-pressed[aria-pressed="true"]', {backgroundColor: dngrL0, color: dngrText});
    setStyle('config-dngr-border-pressed[aria-pressed="true"]', {borderColor: dngrL0});
    setStyle('config-dngr-border-pressed-focus[aria-pressed="true"]:focus', {borderColor: dngrText});
    setStyle('config-dngr-pressed-hover[aria-pressed="true"]:hover', {background: dngrL1});
    setStyle('config-dngr-fg-invalid[aria-invalid="true"]', {color: dngrFg0});

    setStyle('config-ntrl', {backgroundColor: ntrlL0, color: ntrlText});
    setStyle('config-ntrl-hover:hover', {backgroundColor: ntrlL1});
    setStyle('config-ntrl-shadow-focus:focus', {boxShadow: `0 0 0 2px ${ntrlL0}`});
    setStyle('config-ntrl-fg-border', {borderColor: ntrlText});
    setStyle('config-ntrl-fg-border-focus:focus', {borderColor: ntrlText});
    setStyle('config-ntrl-fg-shadow-focus:focus', {boxShadow: `0 0 0 2px ${ntrlText}`});

    const themeElement = document.head.querySelector('meta[name="theme-color"]');
    const c = hclToRgb(headMix);
    themeElement?.setAttribute('content', '#' + ((1 << 24) + (c.red << 16) + (c.green << 8) + c.blue).toString(16).slice(1));
}

//if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
//    setAppBackground(toHcl(overlays[11].background));
//} else {
    setAppBackground(toHcl(overlays[0].background));
//}

export const configSolidPrimaryButton = 'navbutton config-safe config-safe-hover config-body-fg-border config-safe-fg-border-focus config-body-fg-shadow-focus';
export const configSolidSafeButton = 'navbutton config-safe config-safe-hover config-body-fg-border config-safe-fg-border-focus config-body-fg-shadow-focus';
export const configSolidWarnButton = 'navbutton config-warn config-warn-hover config-body-fg-border config-warn-fg-border-focus config-body-fg-shadow-focus';
export const configSolidDngrButton = 'navbutton config-dngr config-dngr-hover config-body-fg-border config-dngr-fg-border-focus config-body-fg-shadow-focus';
export const configSolidNtrlButton = 'navbutton config-ntrl config-ntrl-hover config-body-fg-border config-ntrl-fg-border-focus config-body-fg-shadow-focus';
export const configInvalidWarnButton = 'navbutton config-warn-invalid config-warn-invalid-hover config-body-fg-border config-warn-fg-border-invalid-focus config-body-fg-shadow-focus';

export const configSafeTextBox = 'config-safe-border-empty config-safe-shadow-empty-focus';
export const configDngrTextBox = 'config-dngr-border-empty config-dngr-shadow-empty-focus';
export const configPrimPress = 'config-background-hover config-primary-border-empty config-primary-shadow-empty-focus config-primary-pressed config-primary-pressed-hover config-primary-border-pressed config-primary-border-pressed-focus'
export const configSafePress = 'config-background-hover config-safe-border-empty config-safe-shadow-empty-focus config-safe-pressed config-safe-pressed-hover config-safe-border-pressed config-safe-border-pressed-focus';
export const configDngrPress = 'config-background-hover config-dngr-border-empty config-dngr-shadow-empty-focus config-dngr-pressed config-dngr-pressed-hover config-dngr-border-pressed config-dngr-border-pressed-focus';

function drawCaret(context: CanvasRenderingContext2D, x: number, h: number): void {
    context.fillStyle = 'black';
    context.strokeStyle = 'white';
    context.beginPath();
    context.moveTo(x, h / 2);
    context.lineTo(x + h / 2, h);
    context.lineTo(x - h / 2, h);
    context.closePath();
    context.stroke();
    context.fill();
}


export class Accessibility {
    private controlPanel: ControlPanel;
    private colourButton: HTMLButtonElement;
    private colourPanel: HTMLDivElement;
    private presets: HTMLDivElement;
    private hue: Slider;
    private sat: Slider;
    private lum: Slider;

    private colour = toHcl(overlays[0].background);

    private readonly handleColourButton = ():void => {
        const panel = this.controlPanel.panel();
        if (this.colourPanel.parentElement === panel) {
            panel.removeChild(this.colourPanel);
        } else {
            panel.appendChild(this.colourPanel);
        }
    }

    private readonly handleColourChoice = (event: Event):void => {
        let target = event.target;
        while (target instanceof Node) {
            if (target instanceof HTMLElement && target.dataset.colour) {
                for (const overlay of overlays) {
                    if (target.dataset.colour === overlay.name) {
                        if (isHcl(overlay.background)) {
                            console.log('BG', overlay.background);
                            this.colour = {...overlay.background};
                        } else if (isRgb(overlay.background)) {
                            this.colour = rgbToHcl(overlay.background);
                        }
                        console.log('PRESET', this.colour);
                        this.hue.set(this.colour.hue / 360);
                        this.hue.draw();
                        this.sat.set(this.colour.chr);
                        this.sat.draw();
                        this.lum.set(this.colour.lum);
                        this.lum.draw();
                        setAppBackground(this.colour);
                        return;
                    }
                }
                return;
            }
            target = target.parentElement;
        }
    }

    constructor(controlPanel: ControlPanel) {
        this.controlPanel = controlPanel;
        this.colourButton = mkNode('button', {
            className: 'app-button config-primary-hover config-primary-fg-shadow-focus',
            //attrib: {disabled: 'true'},
            children: [
                mkNode('icon', {icon: faPalette}),
                mkNode('span', {className: 'app-button-text', children: [
                    mkNode('text', {text: translate('CONTROL_COLOUR')})
                ]}),
            ]
        });
        this.colourPanel = mkNode('div', {className: 'tool-bar-vbox'});
        this.presets = mkNode('div', {className: 'tool-bar-hbox', parent: this.colourPanel});
        for (const overlay of overlays) {
            const colour = toString(isHcl(overlay.background) ? hclToRgb(overlay.background) : overlay.background);
            const button = mkNode('button', {className: 'app-button config-primary-hover config-primary-fg-shadow-focus', parent: this.presets, children: [
                mkNode('icon', {icon: faSquare, style: {color: `${colour}`, fontSize: '28px'}}),
                mkNode('span', {className: 'app-button-text ', children: [
                    mkNode('text', {text: overlay.name.toUpperCase()})
                ]}),
            ]});
            button.dataset.colour = overlay.name;
        }
        mkNode('label', {for: 'hue', className: 'slider-label', parent: this.colourPanel, children: [
            mkNode('text', {text: 'Hue'}),
        ]});
        this.hue = new Slider('hue', this.colourPanel, this.colour.hue / 360, (context, hue) => {
            //const {sat, lum} = this.colour
            const w = context.canvas.width
            , h = context.canvas.height
            , s = 360 / w
            ;
            for (let x = 0; x < w; ++x) {
                context.fillStyle = toString(hclToRgb({hue: s * x, chr: 1, lum: 0.5}));
                //context.fillStyle = toString(hsyToRgb({hue: s * x, sat, lum}));
                context.fillRect(x, 0, 1, h);
            }
            drawCaret(context, w * hue, h);
            this.colour.hue = 360 * hue;
        }, () => {
            this.sat.draw();
            this.lum.draw();
            setAppBackground(this.colour);
        });
        mkNode('label', {for: 'sat', className: 'slider-label', parent: this.colourPanel, children: [
            mkNode('text', {text: 'Saturation'}),
        ]});
        this.sat = new Slider('sat', this.colourPanel, this.colour.chr, (context, chr) => {
            const {hue/*, lum*/} = this.colour
            , w = context.canvas.width
            , h = context.canvas.height
            , s = 1 / w
            ;
            for (let x = 0; x < w; ++x) {
                context.fillStyle = toString(hclToRgb({hue, chr: s * x, lum: 0.5}));
                //context.fillStyle = toString(hsyToRgb({hue, sat: s * x, lum}));
                context.fillRect(x, 0, 1, h);
            }
            drawCaret(context, w * chr, h);
            this.colour.chr = chr;
        }, () => {
            //this.hue.draw();
            this.lum.draw();
            setAppBackground(this.colour);
        });
        mkNode('label', {for: 'lum', className: 'slider-label', parent: this.colourPanel, children: [
            mkNode('text', {text: 'Lightness'}),
        ]});
        this.lum = new Slider('lum', this.colourPanel, this.colour.lum, (context, lum) => {
            const {hue, chr} = this.colour
            , w = context.canvas.width
            , h = context.canvas.height
            , s = 1 / w
            ;
            for (let x = 0; x < w; ++x) {
                context.fillStyle = toString(hclToRgb({hue, chr, lum: s * x}));
                context.fillRect(x, 0, 1, h);
            }
            drawCaret(context, w * lum, h);
            this.colour.lum = lum;
        }, () => {
            //this.hue.draw();
            //this.sat.draw();
            setAppBackground(this.colour);
        });
        this.presets.addEventListener('click', this.handleColourChoice);
        this.colourButton.addEventListener('click', this.handleColourButton);
        this.controlPanel.add(this.colourButton);
    }

    public disable(disabled: boolean): void {
        this.colourButton.disabled = disabled;
        if (disabled) {
            removeNode(this.colourPanel);
        }
    }

    public destroy(): void {
        this.hue.destroy();
        this.sat.destroy();
        this.lum.destroy();
        this.presets.removeEventListener('click', this.handleColourChoice);
        this.colourButton.removeEventListener('click', this.handleColourButton);
        this.controlPanel.remove(this.colourButton);
    }
}
