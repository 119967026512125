import {registerLanguage} from '../utils-lang';

const plural = (x: number) => (x > 1 || x < -1);

registerLanguage('Français', {
    LOCALE: 'fr-CA',
    LOGIN_CANDIDATE_ID_LABEL: 'ID',
    LOGIN_CANDIDATE_ID_HINT: 'N\u1d52 d’identification',
    LOGIN_PASSWORD_LABEL: 'MDP',
    LOGIN_PASSWORD_HINT: 'Mot de passe',
    LOGIN_BUTTON: 'Connexion',
    FREE_SPACE: ({quota, used}) => `<p>[<b>${used}Mo</b> utilisés sur <b>${quota}Mo</b> disponibles]</p>`,

    CHOOSE_INSTRUCTIONS: ({cid}) => `${cid ? `<p>Vous avez ouvert une session en tant qu’utilisateur·ice <b>${cid}</b>.</p>` : ''}`,
    CHOOSE_PROCTORED_EXAMITY: `<p><b>INSTRUCTIONS</b></p><p>Cliquez sur <i>Démarrer&nbsp;la&nbsp;surveillance</i> pour accéder à la plateforme EXAMITY<ul><li>Pour créer votre profil</li><li>Pour vous connecter au surveillant et commencer l’examen</li></ul></p>`,
    CHOOSE_SELECT_EXAM: `<p><b>INSTRUCTIONS</b></p><p>Veuillez sélectionner l'examen que vous passez:</p>`,
    CHOOSE_AUTO_START: `<p><b>INSTRUCTIONS</b></p><p>Veuillez attendre; la connexion se fera automatiquement au début de l’examen.</p>`,
    CHOOSE_AUTO_SUPPORT: `<p><b>SOUTIEN</b></p><p>Si la connexion n’est pas établie après le début de l’examen, communiquez avec l’équipe de soutien aux examens.</p>`,
    CHOOSE_MANUAL_START: `<p><b>INSTRUCTIONS</b></p><p>Saisissez le code NIP de l'examen qui vous a été fourni, puis cliquez sur « <i>Take chosen exam</i> » pour commencer</p>`,
    CHOOSE_MANUAL_SUPPORT: `<p><b>SOUTIEN</b></p><p>Si vous rencontrez des difficultés pour démarrer l'examen, veuillez contacter votre équipe d'assistance aux examens.</p>`,
    CHOOSE_PIN_LABEL: 'NIP',
    CHOOSE_AUTO_PIN_HINT: 'NIP démarrage hors ligne',
    CHOOSE_MANUAL_PIN_HINT: 'NIP démarrage',
    CHOOSE_EXAM_BUTTON: 'Commencer l’examen sélectionné',
    CHOOSE_LOGOUT_BUTTON: 'Fermer la session',
    CHOOSE_PROCTOR_BUTTON: 'Démarrer la suveillance',
    CHOOSE_EXAM_HINT: 'Sélectionner l’examen…',
    CHOOSE_WAITING_TO_START: 'Examens en attente de commencer',
    CHOOSE_ALREADY_STARTED: 'Examens déjà commencés',

    ERROR_NETWORK: 'Erreur réseau',
    ERROR_USER_PASS: 'Le n<sup>o</sup> d’identification ou le mot de passe est incorrect',
    ERROR_CONNECTION_TIMEOUT: 'Délai d’attente de connexion',
    ERROR_HTTP: ({code}) => `Erreur de serveur ${code ? ` (HTTP ${code})` : ''}`,
    ERROR_UNKNOWN: ({message}) => `Erreur ${message ? ` [${message}]` : ''}`,
    ERROR_STORAGE: "L’espace de stockage est saturé. Libérez de l’espace ou utilisez un autre ordinateur.",
    ERROR_PIN: 'Le NIP ou l’examen sélectionné est incorrect',
    ERROR_CANDIDATE_NOT_FOUND: 'Le n<sup>o</sup> d’identification est incorrect ou n’est pas inscrit pour cet examen. ',

    DOWNLOAD_TITLE: ({downloadCount}) => `Téléchargement de ${downloadCount} examen${plural(downloadCount) ? 's' : ''}, veuillez attendre…`,
    DOWNLOAD_DESCRIPTION: ({freeSpace}) => `<p>Le téléchargement peut prendre plusieurs minutes en fonction de votre connectivité réseau.</p>${freeSpace}`,

    PREPARING_TITLE: 'Préparation de l’examen, veuillez attendre…',
    PREPARING_DESCRIPTION: ({freeSpace}) => `<p>La préparation peut prendre plusieurs minutes selon les spécifications de votre ordinateur.</p>${freeSpace}`,

    CONTROL_OVERVIEW: 'APERÇU',
    CONTROL_LANGUAGE: 'LANGUE',
    CONTROL_FINISH: 'TERMINER',
    CONTROL_CALCULATOR: 'CALCULATRICE',
    CONTROL_COLOUR: 'COULEUR',
    CONTROL_VERSION: 'VERSION',
    CONTROL_USERID: 'ID UTILILSATEUR',
    CONTROL_PREVIOUS: 'PRÉCÉDENTE',
    CONTROL_NEXT: 'SUIVANTE',
    CONTROL_PREVIOUS_CASE: 'CAS PRÉCÉDENT',
    CONTROL_NEXT_CASE: 'CAS SUIVANT',
    CONTROL_PREVIOUS_CANDIDATE: 'CANDIDAT(E) PRÉCÉDENT(E)',
    CONTROL_NEXT_CANDIDATE: 'CANDIDAT(E) SUIVANT(E)',
    CONTROL_PREVIOUS_STATION: 'STATION PRÉCÉDENTE',
    CONTROL_NEXT_STATION: 'STATION SUIVANTE',
    CONTROL_NOTES: 'NOTES',
    CONTROL_CONNECT: 'CONNECTER',
    CONTROL_CONNECTING: 'CONNECTANT',
    CONTROL_DISCONNECT: 'DÉCONNECTER',
    CONTROL_DISCONNECTING: 'DÉCONNECTANT',
    CONTROL_ID_VALIDATE: ({id}) => `VALIDER ${id}`,
    CONTROL_ID_INVALIDATE: ({id}) => `INVALIDER ${id}`,
    CONTROL_MUTE: 'MUET',
    CONTROL_UNMUTE: 'RÉACTIVER',
    CONTROL_CAMERA: 'VIDÉO',
    CONTROL_SPEAKERS: 'HAUT-PARLEUR',
    CONTROL_MICROPHONE: 'MICROPHONE',
    CONTROL_HIGHLIGHT: '(DE)SURLIGNEUR',

    TITLE_ROUND: 'tour',
    TITLE_STATION: 'station',
    TITLE_CIRCUIT: 'circuit',
    TITLE_CASE: ({n, m}) => `cas&nbsp;<b>${n}</b>&nbsp;sur&nbsp;<b>${m}</b>`,
    TITLE_CONNECT_TO: ({factor}) => `connecté&nbsp;à&nbsp;${factor}`,

    NOTIFICATION_CONNECT: '<center>Cliquez sur le bouton <b>CONNECTER</b>  ci-dessus pour commencer.</center>',
    NO_CANDIDATE: 'Pas de candidat à ce tour.',

    MEETING_POOR_CONNECTION: 'Mauvaise connexion: vous pouvez avoir des problèmes de qualité audio et vidéo.',
    MEETING_STATUS_ERROR: ({err}) => `État de sortie de la réunion: ${err}`,

    TIMER_REMAINING: 'TEMPS RESTANT',
    TIMER_ELAPSED: 'TEMPS ÉCOULÉ',
    TIMER_ROUND_REMAINING: 'TEMPS RESTANT',

    OVERVIEW_TITLE: 'Aperçu',
    OVERVIEW_NO_CANDIDATE: 'pas de candidat',
    RESOURCE_RESTRICTED_SHOW: 'Afficher',
    RESOURCE_RESTRICTED_HIDE: 'Masquer',

    ANSWER_OPTIONAL: 'facultative',
    ANSWER_REQUIRED: 'obligatoire',
    ANSWER_TITLE: 'Réponse',
    ANSWER_FLAG_TIP: 'Marquer cette question',
    SBA_ANSWER_TIP: 'Sélectionner cette réponse',
    SBA_ELIMINATE_TIP: 'Éliminer cette réponse',
    NOT_ANSWERED: 'Réponse non fournie',

    PAUSED_TITLE: 'Examen mis en pause',
    PAUSED_DESCRIPTION: '<p>Un administrateur <strong>a mis l’examen en pause</strong> à distance.<p>',

    STOPPED_TITLE: 'Examen terminé',
    STOPPED_CLEANUP: '<p>Nettoyage en cours; veuillez attendre...<p>',

    MARKSHEET_INVALID: ({invalid}) => `Les critères obligatoires suivants nécessitent une réponse:\n\n${invalid}\n\nOK - pour quitter cette feuille de notes.\n\nCANCEL - pour rester sur cette feuille de notes et voir quels critères manquent.`,

    FINISH_TITLE: 'Vous avez vraiment terminé?',
    FINISH_DESCRIPTION: 'Appuyez sur « <i>Soumettre l’examen</i> » avant de fermer l’onglet ou la fenêtre.</p>',
    FINISH_ANSWERED: '<p>Vous avez répondu à toutes les questions.</p>',
    FINISH_UNANSWERED: ({totalUnanswered}) => `<p>Il reste <strong>${totalUnanswered}</strong> question${plural(totalUnanswered) ? 's' : ''} sans réponse..</p>`,
    FINISH_MANDATORY_UNANSWERED: ({totalUnanswered, mandatoryUnanswered}) => `<p>Il reste <strong>${totalUnanswered}</strong> question${plural(totalUnanswered) ? 's' : ''} (${mandatoryUnanswered} obligatoire) ${plural(totalUnanswered) ? 's' : ''} sans réponse.</p>`,
    FINISH_SUBMITTED: '<p><b>Toutes vos réponses ont été envoyées au serveur.</b></p>',
    FINISH_UNSUBMITTED: ({unsubmitted}) => `<p>Il reste <strong>${unsubmitted}</strong> réponse${plural(unsubmitted) ? 's' : ''} à envoyer au serveur.</p><p>Vous pouvez poursuivre l’examen; les réponses seront enregistrées dans votre ordinateur.</p><p>Les réponses non envoyées sont causées par des problèmes de réseau; vérifiez si le vôtre fonctionne et réessayez avec le bouton ci-dessous. Vous devez soumettre ces réponses avant de pouvoir terminer l’examen. Communiquez avec l’équipe de soutien aux examens au besoin.</p>`,
    FINISH_CONTINUE: 'Poursuivre l’examen',
    FINISH_NOW: 'Soumettre l’examen',

    ALERT_TITLE: 'Réponse requise',
    LOGOUT_MESSAGE: "<p>Vous n'êtes pas connecté·e. Cliquez sur Connexion ci-dessous.</p>",
    LOGIN_AGAIN: 'CONNEXION',
});
