import { makeElements, removeChildren, NodeSpecObject, safeJsonParse, isIndexed, getFreeSpace } from './utils';
import { ExamMap, getProctors, proctorExamity, ExamItem } from './exam-service';
import { ExamId } from './utils-zip';
import { translate } from './utils-lang';
import { urlWithCredentials } from 'utils-net';
import { ReconnectingEventSource } from 'utils-events';
import { configSolidNtrlButton, configSolidSafeButton } from 'exam-accessibility';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { dbPut } from './utils-db';
import { alertModal } from './utils-progress';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
declare global {
    interface EventSourceEventMap {
        'exam-started': MessageEvent;
        'examlist-changed': MessageEvent;
    }
}

interface ExamSelectUi {
    examSelect: HTMLDivElement;
    examLogo: HTMLDivElement;
    examText: HTMLDivElement;
    examInstructions: HTMLDivElement;
    examProctor: HTMLInputElement;
    examChoiceDiv: HTMLDivElement;
    examChoice: HTMLSelectElement;
    examChoiceIcon: HTMLSpanElement;
    examPinLabel: HTMLDivElement;
    examPin: HTMLInputElement;
    pinError: HTMLDivElement;
    examGo: HTMLInputElement;
    examBack: HTMLInputElement;
}

function examSelectUi(): NodeSpecObject<ExamSelectUi> {
    return {
        examSelect: { elem: 'div', className: 'login-panel config-background' },
        examLogo: {
            elem: 'div', className: 'logo-panel', parent: 'examSelect', children: [{
                elem: 'img', className: 'client-logo', attrib: {
                    alt: window.location.host.replace(/practique\.net(:[0-9]+)?$/, ''),
                    draggable: 'false',
                    src: '/static/images/client-logo.png'
                }
            }]
        },
        examInstructions: {
            elem: 'div', className: 'choose-instructions', parent: 'examSelect'
        },
        examProctor: {
            elem: 'input', className: configSolidSafeButton, parent: 'examSelect',
            attrib: { type: 'button', value: translate('CHOOSE_PROCTOR_BUTTON') }
        },
        examChoiceDiv: {elem: 'div', parent: 'examSelect', style: {
            position: 'relative',
            marginTop: '4px',
            marginBottom: '4px',
            padding: '0',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'baseline',
            justifyContent: 'stretch',
        }},
        examChoice: { elem: 'select', className: 'exam-choice ' + configSolidSafeButton, parent: 'examChoiceDiv'},
        examChoiceIcon: { elem: 'icon', icon: faCaretDown, className: 'config-ntrl', parent: 'examChoiceDiv', style: {
            display: 'block',
            position: 'absolute',
            right: '0',
            top: '0',
            bottom: '0',
            padding: '0 16px 0 8px',
            fontSize: '28px',
            backgroundColor: 'inherit',
            margin: '1.5px 1.5px 1.5px 0',
            pointerEvents: 'none',
        }},

        examPinLabel: {
            elem: 'div', className: 'exam-label', parent: 'examSelect',
            children: [
                { elem: 'text', text: translate('CHOOSE_PIN_LABEL') }
            ]
        },
        examPin: {
            elem: 'input', parent: 'examPinLabel',
            attrib: { type: 'text', autocorrect: 'off', autocapitalize: 'none' }
        },
        pinError: {
            elem: 'div', parent: 'examSelect', className: 'exam-error-text', style: {
                marginBottom: '16px',
            }
        },
        examGo: {
            elem: 'input', className: configSolidSafeButton, parent: 'examSelect',
            attrib: { type: 'button', value: translate('CHOOSE_EXAM_BUTTON') }
        },
        examBack: {
            elem: 'input', className: configSolidNtrlButton, parent: 'examSelect', //style: {marginTop: '4.8rem'},
            attrib: { type: 'button', value: translate('CHOOSE_LOGOUT_BUTTON') }
        },
        examText: {elem: 'div', parent: 'examSelect'},
    };
}

interface ExamOptionUi {
    examOption: HTMLOptionElement;
    optionText: Text;
}

const examOptionUi = {
    examOption: { elem: 'option' },
    optionText: { elem: 'text', parent: 'examOption' }
};

export interface ChoiceContext {
    parent: HTMLElement;
    badPin: boolean;
    badCid: boolean;
    examPin?: string;
    candidateId: string;
    //forceCandidateId: string;
    exams: ExamMap;
    chosenExamId?: string;
    manualStart: boolean;
    proctored: boolean;
}

export interface ChosenExam {
    kind: 'exam';
    examId: ExamId;
    examPin: string;
}

export interface Logout {
    kind: 'logout';
}

/*interface StartMessage {
    examid: string;
    pin: string;
    users: {[x:string]:string};
    timestamp: number;
}*/

// eslint-disable-next-line @typescript-eslint/no-explicit-any
/*function isStartMessage(x: any): x is StartMessage {
    return x && typeof x === 'object' &&
        typeof x.examid === 'string' &&
        typeof x.pin === 'string' &&
        typeof x.users === 'object' &&
        typeof x.timestamp === 'number';
}*/

interface ExamListChangedMessage {
    users: string[];
    timestamp: number;
}

function isExamListChangedMessage(x: unknown): x is ExamListChangedMessage {
    return isIndexed(x) && Array.isArray(x.users) && typeof x.timestamp === 'number';
}

export class AssignmentViewer {
    private readonly parent: HTMLElement;
    private si: ExamSelectUi;
    private badPin: boolean;
    private readonly candidateId: string;
    //private someProctored: boolean;
    //private proctors?: Proctors;
    private eventSource: ReconnectingEventSource;
    private manualStart: boolean;
    private exams: ExamMap;
    private usePin?: string;
    private proctored: boolean;

    private makeOpt({name, value = '', disabled = false, selected = false, hidden = false}: {name: string, value?: string, disabled?: boolean, selected?: boolean, hidden?: boolean}) {
        const oi = makeElements(examOptionUi) as ExamOptionUi;
        oi.examOption.value = value;
        oi.optionText.nodeValue = name;
        oi.examOption.disabled = disabled;
        oi.examOption.selected = selected;
        oi.examOption.hidden = hidden;
        this.si.examChoice.appendChild(oi.examOption);
    }

    constructor(context: ChoiceContext) {
        this.manualStart = context.manualStart;
        this.si = makeElements(examSelectUi()) as ExamSelectUi;
        this.exams = context.exams;
        this.proctored = context.proctored;
        this.candidateId = context.candidateId;
        this.parent = context.parent;
        this.badPin = context.badPin;

        const {started, waiting} = Array.from(context.exams.entries()).reduce((acc, x) => {
            if (!x[1].size) {
                acc.failed.push(x);
            } else if (!context.proctored && x[1].proctored) {
                acc.proctored.push(x);
            } else if (x[1].pin || x[1].state === 'STARTED') {
                acc.started.push(x);
            } else {
                acc.waiting.push(x)
            }
            return acc;
        }, {
            started: new Array<[string, ExamItem]>(),
            waiting: new Array<[string, ExamItem]>(),
            proctored: new Array<[string, ExamItem]>(),
            failed: new Array<[string, ExamItem]>(),
        });

        this.makeOpt({name: translate('CHOOSE_EXAM_HINT'), disabled: true, selected: true, hidden: true});
        if (started.length > 0) {
            this.makeOpt({name: translate('CHOOSE_ALREADY_STARTED'), disabled: true});
            for (const [id, ex] of started) {
                this.makeOpt({name: ex.full_title, value: id, selected: context.chosenExamId === id || started.length === 1});
            }
            if (started.length === 1) {
                this.usePin = started[0][1]?.pin;
            }
        }
        if (waiting.length > 0) {
            this.makeOpt({name: translate('CHOOSE_WAITING_TO_START'), disabled: true});
            for (const [id, ex] of waiting) {
                this.makeOpt({name: ex.full_title, value: id, selected: context.chosenExamId === id});
            }
        }

        if (started.length > 0 || waiting.length > 0) {
            this.si.examChoice.style.display = 'block';
            this.si.examChoiceDiv.style.display = 'block';
        } else {
            this.si.examChoice.style.display = 'none';
            this.si.examChoiceDiv.style.display = 'none';
        }

        if (this.badPin) {
            this.si.examPin.className = 'navigation-text exam-pin exam-error';
            this.si.pinError.textContent = translate('ERROR_PIN');
            this.si.pinError.style.display = 'block';
            this.badPin = false;
        } else {
            this.si.examPin.className = 'navigation-text exam-pin';
            this.si.pinError.style.display = 'none';
        }

        if (context.examPin) {
            this.si.examPin.value = context.examPin;
            this.si.examPinLabel.style.display = 'flex';
        } else {
            this.si.examPinLabel.style.display = 'none';
        }

        this.si.examGo.style.display = (context.examPin ?? this.usePin) ? 'block' : 'none';
        this.si.examProctor.style.display = 'none';
        this.eventSource = new ReconnectingEventSource(new URL(urlWithCredentials('/app/all/events/'), window.location.origin));
        this.eventSource.addEventListener('exam-started', this.handleStartMessage);
        this.eventSource.addEventListener('examlist-changed', this.handleExamlistMessage);
    }

    destroy(): void {
        this.eventSource.removeEventListener('examlist-changed', this.handleExamlistMessage);
        this.eventSource.removeEventListener('exam-started', this.handleStartMessage);
        this.eventSource.destroy();
        removeChildren(this.parent);
    }

    private readonly handleStartMessage = (event: MessageEvent<string>): void => {
        console.warn('DEPRECATED exam-start EVENT', event);
    }

    private readonly handleExamlistMessage = async (event: MessageEvent<string>): Promise<void> => {
        try {
            await dbPut('users', 'examCount', 0);
            console.log('SSE_CHANGE', event.data);
            const data = safeJsonParse(event.data);
            if (isExamListChangedMessage(data) && data.users.indexOf(this.candidateId) >= 0) {
                window.location.reload();
            }
        } catch (err) {
            alertModal(`Message receive failed: ${String(err)}`);
        }
    }

    private readonly handlePin = (): void => {
        this.si.examPin.className = 'navigation-text exam-pin';
        this.si.pinError.style.display = 'none';

        this.usePin = this.exams.get(this.si.examChoice.value)?.pin;
        this.si.examGo.style.display = (this.usePin || (this.si.examChoice.value && this.si.examPin.value)) ? 'block' : 'none';
    }

    private readonly handleChoice = (): void => {
        if (this.si.examChoice.value) {
            const exam = this.exams.get(this.si.examChoice.value);
            if (exam) {
                this.si.examChoice.className = 'exam-choice ' + configSolidNtrlButton;
                const manualStart = this.manualStart || !exam.autostart;
                this.si.examInstructions.innerHTML = translate('CHOOSE_INSTRUCTIONS', {cid: this.candidateId}) + (manualStart
                    ? translate('CHOOSE_MANUAL_START') + translate('CHOOSE_MANUAL_SUPPORT')
                    : translate('CHOOSE_AUTO_START') + translate('CHOOSE_AUTO_SUPPORT'));
                //this.si.examPin.className = 'navigation-text exam-pin';
                this.si.examPin.setAttribute('placeholder', translate(manualStart ? 'CHOOSE_MANUAL_PIN_HINT' : 'CHOOSE_AUTO_PIN_HINT'));
                //this.si.pinError.style.display = 'none';
                this.usePin = exam.pin;
                this.si.examPinLabel.style.display = this.usePin ? 'none' : 'flex';
                this.si.examGo.style.display = (this.usePin || this.si.examPin.value) ? 'block' : 'none';
            }
        }
    }

    public async getExamChoice(): Promise<ChosenExam|Logout> {
        const {used, quota} = await getFreeSpace();
        if (used != null && quota != null) {
            this.si.examText.innerHTML = translate('FREE_SPACE', {used, quota});
        }
        return new Promise(async resolve => {
            if (this.si.examChoice.children.length > 1) {
                this.handleChoice();
                this.si.examPin.oninput = this.handlePin;
                this.si.examPin.onchange = this.handlePin;
                this.si.examChoice.onchange = this.handleChoice;
                this.si.examGo.onclick = (): void => {
                    const examPin = this.usePin ?? this.si.examPin.value.trim();
                    const examId = this.si.examChoice.value.trim();
                    if (examPin && examId) {
                        this.parent.removeChild(this.si.examSelect);
                        resolve({kind: 'exam', examId, examPin});
                    }
                };
            } else {
                this.si.examChoice.style.display = 'none';
                this.si.examGo.style.display = 'none';
                this.si.examPinLabel.style.display = 'none';
            }

            const proctors = await getProctors();
            this.si.examInstructions.innerHTML = translate('CHOOSE_INSTRUCTIONS', {cid: this.candidateId}) + (proctors.examity
                ? translate('CHOOSE_PROCTORED_EXAMITY')
                : (this.si.examChoice.children.length > 1)
                    ? translate('CHOOSE_SELECT_EXAM')
                    : translate('CHOOSE_NO_EXAMS')
            );

            if (!this.proctored && proctors.examity) {
                this.si.examChoice.className = 'exam-choice ' + configSolidNtrlButton;
                this.si.examProctor.style.display = 'block';
                this.si.examProctor.onclick = (): void => {
                    if (proctors.examity) {
                        proctorExamity(proctors.examity);
                    }
                };
            }
            this.si.examBack.onclick = (): void => {
                this.parent.removeChild(this.si.examSelect);
                resolve({kind: 'logout'});
            };
            this.parent.appendChild(this.si.examSelect);
        });
    }
}


