import { Transform } from '@p4b/utils';
import { CanvasRenderingContext2D } from 'canvas';

export interface RendererFactory {
    name: string;
    isThis(resource: Img): boolean;
    hasMime(mime: string): boolean;
    makeImg(id: string, buffer: ArrayBuffer): Promise<Img>;
    addImg?(img: Img, buffer: ArrayBuffer): Promise<Img>;
    makeRenderer(resource: Img): Renderer;
}

const rendererTypes: RendererFactory[] = [];

export function forEachRendererType(f: (rf: RendererFactory) => void): void {
    rendererTypes.forEach(f);
}

export function registerRendererType(rf: RendererFactory): void {
    console.info('REGISTER_RENDERER', rf.name)
    rendererTypes.push(rf);
}

export async function makeRenderer(mime: string, buffer: ArrayBuffer, id = ''): Promise<Renderer|undefined> {
    for (const rendererType of rendererTypes) {
        console.log(`TRY ${rendererType.name} ON ${mime}`);
        if (rendererType.hasMime(mime)) {
            console.log('BUFFER', buffer);
            const img = await rendererType.makeImg(id, buffer);
            console.log('SUCCESS');
            const renderer = rendererType.makeRenderer(img);
            await renderer.init();
            return renderer;
        } else {
            console.log('FAILED');
        }
    }
    return;
}

export function rendererFromImage(image: Img): Renderer|undefined {
    for (const rendererType of rendererTypes) {
        console.log(`TRY ${rendererType.name}`);
        if (rendererType.isThis(image)) {
            console.log('SUCCESS');
            return rendererType.makeRenderer(image);
        }
    }
    return;
}

export enum IType {
    Unknown,
    Dicom,
    Tiff,
    Std,
    Video,
    Audio,
    Pdf,
    J2k,
}

export interface Frame {
    rows?: number;
    cols?: number;
    dbOffset? : number;
    data?: ArrayBuffer;
    dataSize: number;
}

export interface Img {
    id: string;
    iType: IType;
    mime?: string;
    caption? : string;
    rows?: number;
    cols?: number;
    distribution?: 'all' | 'restricted';
    //data: (ArrayBuffer|null)[];
    //dataSize: number[];
    frameCount: number;
    frames: Frame[];
}

export interface Renderer {
    img: Img;
    index: number;
    init(): Promise<void>;
    render(): Promise<void>;
    renderThumbnail(): Promise<ImageData>;
    convexMean({y0, y1, f}: {y0: number, y1: number, f: (y: number) => {x0: number, x1: number}}): {mean?: number, stddev?: number};
    animationFrame(context: CanvasRenderingContext2D, base: Transform): Promise<void>;
    load(
        resources: {
            getImageBegin(): Promise<void>;
            getImageFrame(start: number, end: number): Promise<ArrayBuffer|undefined>;
            getImageEnd(): Promise<void>;
        },
        render: () => Promise<void>,
        progress: (p: number) => void
    ): Promise<void>;
    destroy(): void;
}

