import { faHighlighter } from '@fortawesome/free-solid-svg-icons';
import { ControlPanel } from './question-base';
import { canContainPhrasingContent, mkNode } from './utils';
import { translate } from './utils-lang';

function inMark(node: Node): boolean {
    let n: Node|null = node;
    while (n) {
        if (n.nodeName === 'MARK') {
            return true;
        }
        n = n.parentNode;
    }
    return false;
}

function mark(text: Text, startOffset: number, endOffset: number) {
    //console.log('MARK', text, startOffset, endOffset);
    if (text.length === 0 || startOffset >= endOffset) {
        return;
    }
    let selected = text;
    if (startOffset > 0 && startOffset < selected.length) {
        selected = selected.splitText(startOffset);
        endOffset -= startOffset;
    }
    if (endOffset > 0 && endOffset < selected.length) {
        selected.splitText(endOffset);
    }
    const mark = document.createElement('mark');
    const parent = selected.parentElement;
    const nextSibling = selected.nextSibling;
    mark.appendChild(selected);
    parent?.insertBefore(mark, nextSibling);
}

function unmark(text: Text, startOffset: number, endOffset: number) {
    //console.log('UNMARK', text, startOffset, endOffset);
    if (text.length === 0 || startOffset >= endOffset) {
        return;
    }
    let unselected = text;
    if (startOffset > 0 && startOffset < unselected.length) {
        unselected = unselected.splitText(startOffset);
        endOffset -= startOffset;
    }
    if (endOffset > 0 && endOffset < unselected.length) {
        unselected.splitText(endOffset);
    }
    const parent = unselected.parentElement;
    if (parent?.nodeName === "MARK") {
        const grandParent = parent.parentElement;
        const nextSibling = parent.nextSibling;
        grandParent?.removeChild(parent);
        const children = Array.from(parent.childNodes);
        for (const child of children) {
            if (child === unselected) {
                grandParent?.insertBefore(child, nextSibling);
            } else {
                const mark = document.createElement('mark');
                mark.appendChild(child);
                grandParent?.insertBefore(mark, nextSibling);
            }
        }
    }
}

function getExtents(node: Node, extents: [number, number][], offset: number): number {
    let length = 0;
    if (node.nodeName === 'MARK') {
        length = node.textContent ? node.textContent.length : 0
        extents.push([offset, length]);
        //console.log('EXTENT', offset, node.textContent);
    } else if (node instanceof Text) {
        length = node.nodeValue ? node.nodeValue.length : 0;
    } else {
        const children = Array.from(node.childNodes);
        for (const child of children) {
            length += getExtents(child, extents, offset + length);
        }
    }
    return length;
}

function setExtents(node: Node, extents: [number,number][], offset: number): number {
    let length = 0;
    if (node instanceof Text) {
        let text: Text = node;
        while (extents.length > 0 && extents[0][0] >= offset + length && extents[0][0] < offset + length + text.length) {
            const selected = text.splitText(extents[0][0] - offset - length);
            length += text.length;
            if (extents[0][1] < selected.length) {
                text = selected.splitText(extents[0][1]);
                length += selected.length;
            } else {
                text = selected;
            }
            const parent = selected.parentNode;
            const nextSibling = selected.nextSibling;
            const mark = document.createElement('mark');
            mark.appendChild(selected);
            parent?.insertBefore(mark, nextSibling);
            extents.shift();
        }
        length += text.length;
    } else {
        const children = Array.from(node.childNodes);
        for (const child of children) {
            length += setExtents(child, extents, offset + length);
            if (extents.length === 0) {
                break;
            }
        }
    }
    return length;
}

export class Highlight {
    private controlPanel: ControlPanel;
    private notesIconSpan: HTMLSpanElement;
    private notesText: Text;
    private notesButton: HTMLButtonElement;
    private onChange: (extents: [number,number][]) => void;
    private page: Node;
    private disable = true;

    public constructor(
        controlPanel: ControlPanel,
        page: Node,
        onChange: (extents: [number,number][]) => void,
    ) {
        this.controlPanel = controlPanel;
        this.page = page;
        this.onChange = onChange;
        this.notesIconSpan = mkNode('span', {children: [
            mkNode('icon', {icon: faHighlighter})
        ]});
        this.notesText = mkNode('text', {text: translate('CONTROL_HIGHLIGHT')})
        this.notesButton = mkNode('button', {
            className: 'app-button config-primary config-primary-hover config-primary-pressed config-primary-pressed-hover config-primary-fg-shadow-focus',
            attrib: {disabled: 'true'},
            children: [
                this.notesIconSpan,
                mkNode('span', {
                    className: 'app-button-text', children: [
                        this.notesText
                    ]
                })
            ]
        });
        this.controlPanel.add(this.notesButton);
        this.notesButton.addEventListener('click', this.handleClick);
        document.addEventListener('selectionchange', this.handleSelectionChange);
    }

    private readonly handleSelectionChange = () => {
        const selection = document.getSelection();
        if (selection && !this.disable) {
            this.notesButton.disabled = selection.isCollapsed;
        }
    }

    private readonly handleClick = () => {
        const selection = document.getSelection();
        if (!selection) {
            return;
        }
        for (let i = 0; i < (selection.rangeCount ?? 0); ++i) {
            const range = selection.getRangeAt(i);
            if (!range.collapsed) {
                let root = range.commonAncestorContainer;
                if (root.nodeType !== Node.ELEMENT_NODE && root.parentElement) {
                    root = root.parentElement;
                }
                const walker = document.createTreeWalker(root, NodeFilter.SHOW_TEXT);

                const selected: Text[] = [];
                const marked: boolean[] = [];
                while(walker.nextNode()) {
                    if (range.intersectsNode(walker.currentNode)) {
                        if (
                            walker.currentNode instanceof Text &&
                            walker.currentNode.parentElement &&
                            canContainPhrasingContent(walker.currentNode.parentElement) &&
                            getComputedStyle(walker.currentNode.parentElement).getPropertyValue('user-select') !== 'none'
                        ) {
                            marked.push(inMark(walker.currentNode));
                            selected.push(walker.currentNode);
                        }
                    }
                }

                const startOffset = (range.startContainer === selected[0]) ? range.startOffset : 0;
                const endOffset = (range.endContainer === selected[selected.length - 1]) ? range.endOffset : selected[selected.length - 1].length;
                if (selected.length === 1) {
                    if (marked[0]) {
                        unmark(selected[0], startOffset, endOffset);
                    } else {
                        mark(selected[0], startOffset, endOffset);
                    }
                } else if (selected.length === 2) {
                    if (marked[0]) {
                        unmark(selected[0], startOffset, selected[0].length);
                    } else {
                        mark(selected[0], startOffset, selected[0].length);
                    }
                    if (marked[1]) {
                        unmark(selected[1], 0, endOffset);
                    } else {
                        mark(selected[1], 0, endOffset);
                    }
                } else if (selected.length > 2) {
                    if (marked[0]) {
                        unmark(selected[0], startOffset, selected[0].length);
                    } else {
                        mark(selected[0], startOffset, selected[0].length);
                    }
                    for (let i = 1; i < selected.length - 1; ++i) {
                        const text = selected[i];
                        if (marked[i]) {
                            unmark(text, 0, text.length);
                        } else {
                            mark(text, 0, text.length);
                        }
                    }
                    if (marked[marked.length - 1]) {
                        unmark(selected[selected.length - 1], 0, endOffset);
                    } else {
                        mark(selected[selected.length - 1], 0, endOffset);
                    }
                }
                if (selected.length > 0) {
                    const extents: [number, number][] = [];
                    getExtents(this.page, extents, 0);
                    console.debug(`SAVE HIGHLIGHT EXTENTS ${JSON.stringify(extents)}`);
                    this.onChange(extents);
                }
            }
        }
        selection.removeAllRanges();
    }

    public disabled(disabled: boolean) {
        if (disabled !== this.disable) {
            this.disable = disabled;
            document.getSelection()?.removeAllRanges();
        }
    }

    public load(extents?: [number,number][]) {
        if (extents) {
            console.debug(`LOAD HIGHLIGHT EXTENTS ${JSON.stringify(extents)}`);
            setExtents(this.page, extents, 0);
        }
    }

    public destroy() {
        document.removeEventListener('selectionchange', this.handleSelectionChange);
        this.notesButton.removeEventListener('click', this.handleClick);
    }
}