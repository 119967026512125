import { mkNode, scrollRangeIntoView, removeNode, yieldMacroTask } from './utils';
import { configSafePress, configSafeTextBox } from './exam-accessibility';


/** Dropdown question textarea UI */
export class Dropdown {
    private isOpen = false;
    private index = -1;
    private highlightIndex = -1;
    private options: string[] = [];

    private disabled: boolean;
    private selectElement: HTMLDivElement;
    private selectedElement: HTMLDivElement;
    private optionsElement: HTMLDivElement;
    private backgroundElement: HTMLElement|null;


    /** Construct Dropdown Question UI */
    public constructor(parent: Node, disabled = false) {
        this.disabled = disabled;
        this.selectElement = mkNode('div', {className: 'answer-dropdown ' + configSafeTextBox, parent: parent, attrib: {tabindex: '0', 'aria-disabled': 'true'}});
        this.selectedElement = mkNode('div', {className: 'answer-dropdown-selected config-background-hover', parent: this.selectElement, attrib: {'aria-expanded': 'false'}});
        this.optionsElement = mkNode('div', {className: 'answer-dropdown-options', parent: this.selectElement, hidden: true});
        this.selectedElement.innerHTML = '<div>&nbsp;</div>';
        this.selectElement.addEventListener('keydown', this.keyHandler);
        this.selectedElement.addEventListener('mousedown', this.selectedDownHandler);
        this.backgroundElement = document.getElementById('answers-inner');
    }

    public addOption(optionName: string, optionDetails?: string, backendId?: string): void {
        const elem = mkNode('button', {className: 'answer-dropdown-item ' + configSafePress, parent: this.optionsElement, attrib: {'data-bid': backendId !== undefined ? backendId: '', 'data-optionname': optionName}});
        elem.innerHTML = `<div class="option-name">${optionName}</div>${(optionDetails) ? `<div class="option-details">${optionDetails}</div>` : ''}`;
        this.options.push(optionName);
    }

    public disable(disabled: boolean): void{
        this.disabled = disabled;
        this.selectElement.setAttribute('aria-disabled', String(disabled));
        for (let i = 0; i < this.optionsElement.children.length; ++i) {
            const option = this.optionsElement.children[i];
            if (option instanceof HTMLButtonElement) {
                option.disabled = disabled;
            }
        }
    }

    /** Free the resources used by LongtextQuestion */
    public destroy(): void {
        this.backgroundElement?.removeEventListener('mousedown', this.windowCloseHandler);
        this.backgroundElement?.removeEventListener('mouseup', this.windowUpHandler);
        this.selectElement.removeEventListener('keydown', this.keyHandler);
        this.selectedElement.removeEventListener('mousedown', this.selectedDownHandler);
        this.optionsElement.removeEventListener('mousedown', this.optionsDownHandler);
        this.optionsElement.removeEventListener('mousemove', this.optionsMoveHandler);
        this.optionsElement.removeEventListener('mouseup', this.optionsUpHandler);
        removeNode(this.selectElement);
    }

    public focus(): void {
        scrollRangeIntoView(this.selectElement, this.selectElement);
    }

    private async open(): Promise<void> {
        this.optionsElement.hidden = false;
        this.selectedElement.setAttribute('aria-expanded', 'true');
        this.isOpen = true;
        this.highlight(this.index);
        this.focus();
        await yieldMacroTask();
        this.selectElement.focus();
        this.optionsElement.addEventListener('mousedown', this.optionsDownHandler);
        this.backgroundElement?.addEventListener('mousedown', this.windowCloseHandler);
    }

    private async close(): Promise<void> {
        this.optionsElement.removeEventListener('mousedown', this.optionsDownHandler);
        this.backgroundElement?.removeEventListener('mousedown', this.windowCloseHandler);
        if (this.highlightIndex !== this.index) {
            this.selectElement.dispatchEvent(new CustomEvent('dropdown-select', {detail: this.highlightIndex, bubbles: true}));
            this.selectedElement.innerHTML = this.options[this.highlightIndex];
            this.index = this.highlightIndex;
        }
        this.optionsElement.hidden = true;
        this.selectedElement.setAttribute('aria-expanded', 'false');
        this.isOpen = false;
    }

    /** Get the answer value */
    public selectedIndex(): number {
        return this.index;
    }

    public select(index: number): void {
        if (this.index != index) {
            this.highlight(index);
            this.selectedElement.innerHTML = this.options[index];
            this.index = index;
        }
    }

    public selectByValue(value: string): void {
        for (let i = 0; i < this.options.length; ++i) {
            if (value === this.options[i]) {
                this.select(i);
                break;
            }
        }
    }

    private highlight(index: number) {
        if (this.highlightIndex >= 0) {
            this.optionsElement.children[this.highlightIndex].setAttribute('aria-pressed', 'false');
        }
        if (index >= 0) {
            this.optionsElement.children[index].setAttribute('aria-pressed', 'true');
        }
        this.highlightIndex = index;
    }

    private getOptionIndex(t: EventTarget|null): number {
        if (t instanceof Node) {
            for (let i = 0; i < this.optionsElement.children.length; ++i) {
                const child = this.optionsElement.children[i];
                if (child.contains(t)) {
                    return i;
                }
            }
        }
        return -1;
    }

    private keyHandler = async (e: KeyboardEvent): Promise<void> => {
        if (e.target instanceof HTMLElement) {
            switch(e.key) {
                case 'Tab': {
                    if (this.isOpen) {
                        this.close();
                    }
                    break;
                }
                case 'Enter':
                    e.preventDefault();
                    if (this.isOpen) {
                        this.close();
                    } else {
                        this.open();
                    }
                    break;
                case 'Escape':
                    if (this.isOpen) {
                        e.preventDefault();
                        this.highlight(this.index);
                        this.close();
                    }
                    break;
                case 'Up':
                case 'ArrowUp':
                    if (!this.disabled && this.isOpen) { // fix keyboard to keep open
                        e.preventDefault();
                        this.highlight((this.highlightIndex < 0) ? this.optionsElement.children.length - 1 : (this.highlightIndex > 0) ? this.highlightIndex - 1 : this.highlightIndex)
                    }
                    break;
                case 'Down':
                case 'ArrowDown':
                    if (!this.disabled && this.isOpen) {
                        e.preventDefault();
                        this.highlight((this.highlightIndex < 0) ? 0 : (this.highlightIndex + 1 < this.optionsElement.children.length) ? this.highlightIndex + 1 : this.highlightIndex);
                    }
                    break;
            }
        }
    }

    private startX?: number;
    private startY?: number;

    private selectedDownHandler = async (e: MouseEvent): Promise<void> => {
        this.startX = e.clientX;
        this.startY = e.clientY;
        this.open();
        if (!this.disabled) {
            this.optionsElement.addEventListener('mousemove', this.optionsMoveHandler);
            this.optionsElement.addEventListener('mouseup', this.optionsUpHandler);
            this.backgroundElement?.addEventListener('mouseup', this.windowUpHandler);
        }
    }

    private optionsDownHandler = (e: MouseEvent): void => {
        if (!this.disabled) {
            const i = this.getOptionIndex(e.target);
            if (i >= 0) {
                this.highlight(i);
                this.startX = undefined;
                this.startY = undefined;
            }
            this.optionsElement.addEventListener('mousemove', this.optionsMoveHandler);
            this.optionsElement.addEventListener('mouseup', this.optionsUpHandler);
            this.backgroundElement?.addEventListener('mouseup', this.windowUpHandler);
        }
    }

    private optionsMoveHandler = (e: MouseEvent): void => {
        if (!this.disabled && (this.startX === undefined || this.startY === undefined || Math.abs(e.clientY - this.startY) > 1 || Math.abs(e.clientX - this.startX) > 1)) {
            const i = this.getOptionIndex(e.target);
            if (i >= 0) {
                this.highlight(i);
            }
        }
    }

    private optionsUpHandler = (e: MouseEvent): void => {
        if (!this.disabled && (this.startX === undefined || this.startY === undefined || Math.abs(e.clientY - this.startY) > 1 || Math.abs(e.clientX - this.startX) > 1)) {
            const i = this.getOptionIndex(e.target);
            if (i >= 0) {
                this.highlight(i);
                this.close();
            }
        }
    }

    private windowCloseHandler = (e: MouseEvent): void => {
        if (this.getOptionIndex(e.target) < 0) {
            this.close();
        }
    }

    private windowUpHandler = () => {
        this.optionsElement.removeEventListener('mousemove', this.optionsMoveHandler);
        this.optionsElement.addEventListener('mouseup', this.optionsUpHandler);
        this.backgroundElement?.removeEventListener('mouseup', this.windowUpHandler);
    }
}