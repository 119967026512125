import { LocalData } from 'exam-service';
import { mkNode, scrollRangeIntoView, removeNode } from 'utils';
import { Question, QuestionContext, QuestionManifest, QuestionBase, Expr, registerAnswerType, QuestionArgs } from 'question-base';
import { Lightbox } from 'lightbox';
import { faLocationCrosshairs } from '@fortawesome/free-solid-svg-icons';
import { configSafePress } from '@p4b/exam-accessibility';
import { Img, rendererFromImage } from '@p4b/image-base';

type Point = [number, number];

function isCoord(x: unknown): x is Point {
    return Array.isArray(x) && x.length === 2 && x.every(n => typeof n === 'number');
}

function eqCoord(p?: Point, q?: Point) {
    return p && q && p[0] === q[0] && p[1] === q[1];
}

export class QuestionHotspot extends QuestionBase implements Question {
    private answerItem: HTMLDivElement;
    private answerButton: HTMLButtonElement;
    private answerText: HTMLSpanElement;
    private value?: Point;
    private updateVisibility: () => void;
    private images: Img[];
    private lightbox: Lightbox;

    public readonly visibilityExpression?: Expr;

    /** Construct LontextQuestion UI */
    public constructor(args: Omit<QuestionArgs, 'showFlag'> & {
        updateVisibility: () => void,
        visibilityExpression?: Expr,
        images: Img[],
    }) {
        super({...args, showFlag: true});
        const {indent, updateVisibility, visibilityExpression} = args;
        const indentRem = (1.6 * (indent ?? 0) + 1.6).toString();
        this.label.style.paddingLeft = `${indentRem}rem`;
        this.answerItem = mkNode('div', {className: 'answer-item', parent: this.column});
        this.answerText = mkNode('span')
        this.answerButton = mkNode('button', {className: 'boolean-button ' + configSafePress, parent: this.answerItem, tabindex: 0, disabled: true, children: [
            mkNode('icon', {icon: faLocationCrosshairs, style: {marginRight: '8px'}}),
            this.answerText,
        ], attrib: {'data-value': 'point'}});
        this.answerText.innerHTML = 'SELECT POINT';
        this.updateVisibility = updateVisibility;
        this.visibilityExpression = visibilityExpression;
        this.images = args.images;
        this.lightbox = args.lightbox;
    }

    /** Load any stored answer */
    public loadAnswer(response?: LocalData): void {
        try {
            if (response && isCoord(response.answer)) {
                this.value = response.answer;
                this.p = {x: this.value[0], y: this.value[1]};
                this.answerText.innerHTML = this.value ? JSON.stringify(this.value) : 'SELECT POINT';
            }
            this.updateVisibility();
            this.answerButton.addEventListener('click', this.point_handler);
        } catch (err) {
            console.error(err);
        }
    }

    public updateDisable() {
        super.updateDisable();
        this.answerButton.disabled = this.isDisabled();
    }

    public loadingComplete(): void {
        super.loadingComplete();
    }

    public setReadOnly(isReadOnly: boolean): void {
        super.setReadOnly(isReadOnly);
    }

    /** Get the answer value */
    public getValue(): string {
        return this.value ? JSON.stringify(this.value) : '';
    }

    /** Set whether this question is visible or hidden */
    public setVisible(vis: boolean): void {
        this.answerItem.style.display = vis ? 'block' : 'none';
        this.context.responses.setVisible(this.qno, this.ano, vis);
    }

    /** Free the resources used by LongtextQuestion */
    public destroy(): void {
        this.answerButton.removeEventListener('click', this.point_handler);
        removeNode(this.answerItem);
        super.destroy();
    }

    public focus(): void {
        scrollRangeIntoView(this.answerItem, this.answerItem);
    }

    //public getAnswer(): AnswerKey & AnswerValue {
    //    return {qno: this.qno, ano: this.ano, answer: this.value || null};
    //}

    private p?: {x: number, y: number};

    private readonly point_handler = async () => {
        if (this.images.length > 0) {
            const viewer = this.lightbox.open();
            await viewer.setDicom(rendererFromImage(this.images[0]));
            viewer.setPoint(this.p, async (p) => {
                this.p = p;
                const value: [number, number] | undefined = p ? [p.x, p.y] : undefined;
                if (!this.isDisabled() && !eqCoord(this.value, value)) {
                    this.value = value;
                    try {
                        await this.context.responses.saveAnswer({qno: this.qno, ano: this.ano}, {answer: value ?? null});
                        this.answerText.innerHTML = value ? JSON.stringify(value) : 'SELECT POINT';
                    } catch (err) {
                        console.error(err);
                    } finally {
                        this.updateVisibility();
                    }
                }
            });
        }
    }
}

registerAnswerType({
    name: 'Text',
    isThis: (answer: PractiqueNet.ExamJson.Definitions.Answer): boolean => {
        return answer.type.toLowerCase() === 'hotspot';
    },
    makeAnswer: (
        qno: number,
        context: QuestionContext,
        updateVisibility: () => void,
        question: QuestionManifest,
        answer: PractiqueNet.ExamJson.Definitions.AnswerHotspot,
        frag: DocumentFragment,
        ano: number,
        lightbox: Lightbox,
        isRemoteShowHide: boolean,
        isOSCE: boolean,
    ): Question => {
        console.debug('HOTSPOT', answer);
        return new QuestionHotspot({
            updateVisibility,
            context,
            qno,
            ano,
            backendQid: question.manifest.backend_id,
            backendAid: answer.backend_id,
            showNumber: question.manifest.answers.length > 1,
            label: answer.label,
            frag,
            lightbox,
            isRemoteShowHide,
            notes: answer.notes,
            indent: answer.indent,
            visibilityExpression: answer.visible,
            resources: question.answersResources[ano],
            mandatory: answer.mandatory,
            type: answer.type.toLowerCase(),
            isOSCE,
            images: question.images,
        });
    }
});
