import { ImageViewerElement } from '@p4b/image-viewer';
import { mkNode, scrollRangeIntoView, removeNode, removeChildren } from '@p4b/utils';
//import { Img } from '@p4b/image-base';
import { faThLarge } from '@fortawesome/free-solid-svg-icons';


export interface LightboxContext {
    resources: {
        getImageBegin(): Promise<void>;
        getImageFrame(start: number, end: number): Promise<ArrayBuffer|undefined>;
        getImageEnd(): Promise<void>;
    },
    navigation: {
        getNavigating(): boolean;
    },
    fullscreenParent?: Element;
    scrollContainer: Element;
    meta?: PractiqueNet.ExamJson.Definitions.ExamMeta,
    controlPanel: {
        add(x: HTMLElement): void,
    },
    gridControlPanel: {
        add(y: HTMLElement): void,
    },
}

export class Lightbox {
    private context?: LightboxContext;
    private viewers = new Map<number, ImageViewerElement>();
    private lightboxPanel: HTMLDivElement;
    private gridButton: HTMLButtonElement;
    private buttonText: HTMLSpanElement;
    private gridPanel?: HTMLDivElement;

    private rows = 1;
    private cols = 1;
    private slots: HTMLElement[] = [];
    private next = 0;
    private readonly gridSizes = ['1x1', '2x2'];
    private readonly sizeMap: {[index: string]: {width: number, height: number}} = {
        '1x1': {width: 1, height: 1},
        '2x2': {width: 2, height: 2},
    };
    private gridIndex = 0;

    private openGridPanel() {
        if (this.context && !this.gridPanel) {
            this.gridPanel = mkNode('div', {className: 'meetingBar'});
            for (const gridSize of this.gridSizes) {
                mkNode('button', {
                    parent: this.gridPanel,
                    attrib: {'data-size': gridSize},
                    className: 'app-button config-primary-hover',
                    children: [
                        mkNode('span', {children: [
                            mkNode('text', {text: gridSize})
                        ]})
                    ]
                });
            }
            this.context.gridControlPanel.add(this.gridPanel);
            this.gridPanel.addEventListener('click', this.handleGridSelect);
        }
    }

    private closeGridPanel() {
        if (this.gridPanel) {
            this.gridPanel.removeEventListener('click', this.handleGridSelect);
            removeNode(this.gridPanel);
            this.gridPanel = undefined;
        }
    }

    private readonly handleGridButton = async () => {
        if (this.gridSizes.length < 3) {
            this.gridIndex = (this.gridIndex + 1) % this.gridSizes.length;
            const name = this.gridSizes[this.gridIndex];
            ({width: this.cols, height: this.rows} = this.sizeMap[name]);
            this.buttonText.textContent = name;
            await this.updateGrid();
        } else {
            if (this.gridPanel) {
                this.openGridPanel();
            } else {
                this.closeGridPanel();
            }
        }
    }

    private readonly handleGridSelect = async (event: Event): Promise<void> => {
        if (this.gridPanel) {
            let elem = event.target;
            while (elem instanceof HTMLElement && !elem.dataset['size']) {
                elem = elem.parentElement;
            }
            const name = elem instanceof HTMLElement && elem.dataset['size'];
            if (name && name in this.gridSizes) {
                const size = this.sizeMap[name];
                this.cols = size.width;
                this.rows = size.height;
                this.buttonText.textContent = name;
            }
            this.closeGridPanel();
            await this.updateGrid();
        }
    }

    private async updateGrid(): Promise<void> {
        if (this.viewers.size > 0) {
            await this.closeAll();
        }
        removeChildren(this.lightboxPanel);
        this.slots = new Array(this.rows * this.cols);
        const xstep = 100.0 / this.cols;
        const ystep = 100.0 / this.rows;
        let k = 0;
        let y0 = 0;
        let y1 = 100;
        for(let j = 0; j < this.rows; ++j) {
            y1 -= ystep;
            let x0 = 0;
            let x1 = 100;
            for (let i = 0; i < this.cols; ++i) {
                x1 -= xstep;
                this.slots[k++] = mkNode('div', {parent: this.lightboxPanel, className: 'config-body-fg-light-border', style: {
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'absolute',
                    left: x0.toString() + '%',
                    top: y0.toString() + '%',
                    right: x1.toString() + '%',
                    bottom: y1.toString() + '%',
                    borderWidth: '1px',
                    borderStyle: 'solid',
                    boxSizing: 'border-box',
                    margin: '0px 2px 2px 2px',
                }});
                x0 += xstep;
            }
            y0 += ystep;
        }
        this.next = 0;
    }

    constructor() {
        this.lightboxPanel = mkNode('div', {className: 'lightbox-panel', attrib: {'aria-hidden': 'true'}});
        this.buttonText = mkNode('span', {className: 'app-button-text', children: [
            mkNode('text', {text: '1x1'}),
        ]});
        this.gridButton = mkNode('button', {
            className: 'app-button config-primary-hover config-primary-fg-shadow-focus',
            //attrib: {disabled: 'true'},
            children: [
                mkNode('icon', {icon: faThLarge}),
                this.buttonText,
            ]
        });

        this.gridButton.addEventListener('click', this.handleGridButton);
    }

    public args(context: LightboxContext, parent: Node) {
        this.context = context;
        if (!context.meta?.disableDicomGrid) {
            this.context.controlPanel.add(this.gridButton)
        }
        parent.insertBefore(this.lightboxPanel, parent.firstChild);
        this.updateGrid();
    }

    public async destroy(): Promise<void> {
        if (this.viewers.size > 0) {
            await this.closeAll();
        }
        this.closeGridPanel();
    }

    public async closeAll(): Promise<void> {
        for (let i = 0; i < this.slots.length; ++i) {
            const viewer = this.viewers.get(i);
            if (viewer) {
                await viewer.setDicom();
                removeNode(viewer);
                //await viewer.destroy(); // callback will delete map entry & hide lightboxPanel
            }
        }
    }

    public async close(id: string): Promise<void> {
        for (let i = 0; i < this.slots.length; ++i) {
            const viewer = this.viewers.get(i);
            if (viewer && (id === undefined || id === viewer.getRenderer()?.img.id)) {
                await viewer.setDicom();
                removeNode(viewer);
                //await viewer.destroy(); // callback will delete map entry & hide lightboxPanel
            }
        }
    }

    public setHeight(height: number): void {
        this.lightboxPanel.style.height = height + 'px';
    }

    public setVisible(visible: boolean): void {
        this.lightboxPanel.setAttribute('aria-hidden', visible ? 'false' : 'true');
        this.lightboxPanel.dispatchEvent(new CustomEvent('visibility', {
            bubbles: true,
            detail: {
                visibility: visible,
            }
        }));
    }

    private newViewer(x: number): ImageViewerElement {
        const viewer: ImageViewerElement = document.createElement('image-viewer');
        if (this.context) {
            viewer.args = {
                fullscreenParent: this.context.fullscreenParent,
                resources: this.context.resources,
                navigation: this.context.navigation,
                //toggleFullscreen: (fullscreen?: boolean) => this.toggleFullscreen(viewer, fullscreen),
            };
        }
        viewer.closeHook = (): void => {
            //thumbnail.select(false);
            //viewer.removeEventListener('dblclick', this.handleDblclick);
            scrollRangeIntoView(this.lightboxPanel);
            this.viewers.delete(x);
            console.log('CLOSE', x, this.viewers.size);
            if (this.viewers.size === 0) {
                this.setVisible(false);
            }
        };
        removeChildren(this.slots[x]);
        this.slots[x].appendChild(viewer);
        this.viewers.set(x, viewer);
        return viewer;
    }

    private originalParent?: HTMLElement;

    /*
    private setFullscreen(viewer: ImageViewerElement) {
        this.originalParent = viewer.parentElement ?? undefined;
        this.context?.fullscreenParent.appendChild(viewer);
        if (viewer.firstChild instanceof HTMLElement) {
            viewer.firstChild.className = 'viewer-panel-fullscreen config-background';
            viewer.firstChild.style.zIndex = '9999';
        }
    }
    */

    /*/
    private unsetFullscreen(viewer: ImageViewerElement) {
        if (this.originalParent) {
            this.originalParent.appendChild(viewer);
            if (viewer.firstChild instanceof HTMLElement) {
                viewer.firstChild.className = 'viewer-panel config-background';
            }
        }
    }
    */

    /*
    private toggleFullscreen(viewer: ImageViewerElement, fullscreen?: boolean) {
        if (viewer.parentElement === this.context?.fullscreenParent && fullscreen !== true) {
            this.unsetFullscreen(viewer);
        } else if (fullscreen !== false) {
            this.setFullscreen(viewer);
        }
    }
    */

    /*
    private handleDblclick = (event: MouseEvent): void => {
        const viewer = event.target;
        if (viewer instanceof ImageViewerElement) {
            this.toggleFullscreen(viewer);
        }
    };
    */

    public disabled(isDisabled: boolean): void {
        this.viewers.forEach(viewer => {
            viewer.disabled = isDisabled;
        })
        this.gridButton.disabled = isDisabled;
        if (isDisabled) {
            this.closeGridPanel();
            this.closeAll();
        }
    }

    public open(): ImageViewerElement {
        for (let i = 0; i < this.slots.length; ++i) {
            if (!this.viewers.get(i)) {
                this.next = i;
                break;
            }
        }
        if (this.next >= this.slots.length) {
            this.next = 0
        }
        if (!this.viewers.has(this.next)) {
            const viewer = this.newViewer(this.next++);
            this.setVisible(true);
            return viewer;
        } else {
            const viewer = this.viewers.get(this.next++);
            if (!viewer) {
                throw new Error('Viewer is null');
            }
            return viewer;
        }
    }
}
