import {registerLanguage} from '../utils-lang';

const plural = (x: number) => (x > 1 || x === 0 || x < -1);

registerLanguage('English', {
    LOCALE: 'en-GB',
    LOGIN_CANDIDATE_ID_LABEL: 'ID',
    LOGIN_CANDIDATE_ID_HINT: 'Login ID',
    LOGIN_PASSWORD_LABEL: 'PW',
    LOGIN_PASSWORD_HINT: 'Password',
    LOGIN_BUTTON: 'LOGIN',
    FREE_SPACE: ({quota, used}) => `<p>[<b>${used}MB</b> storage used of <b>${quota}MB</b> available]</p>`,

    CHOOSE_INSTRUCTIONS: ({cid}) => `<p>You are logged in as user <b>${cid}</b>.</p>`,
    CHOOSE_PROCTORED_EXAMITY: `<p><b>INSTRUCTIONS</b></p><p>Please click on <i>Start&nbsp;proctoring</i> to access the EXAMITY platform<ul><li>To create your profile</li><li>To connect to your proctor and start your exam</li></ul></p>`,
    CHOOSE_PROCTORED_SUPPORT: `<p><b>SUPPORT</b></p><p>If you have difficulties connecting to the proctor, please contact your exam support team.</p>`,
    CHOOSE_NO_EXAMS: `<p>There are no exams available for you to take.</p>`,
    CHOOSE_SELECT_EXAM: `<p><b>INSTRUCTIONS</b></p><p>Please select the exam you are taking:</p>`,
    CHOOSE_AUTO_START: `<p><b>INSTRUCTIONS</b></p><p>Please wait, you will be automatically connected when your exam is opened.</p>`,
    CHOOSE_AUTO_SUPPORT: `<p><b>SUPPORT</b></p><p>If you are not connected after the start time of your exam, please contact your exam support team.</p>`,
    CHOOSE_MANUAL_START: `<p><b>INSTRUCTIONS</b></p><p>Enter the exam PIN you have been provided with, and then click <i>Take chosen exam</i> to start</p>`,
    CHOOSE_MANUAL_SUPPORT: `<p><b>SUPPORT</b></p><p>If you have difficulties starting the exam, please contact your exam support team.</p>`,
    CHOOSE_PIN_LABEL: 'PIN',
    CHOOSE_AUTO_PIN_HINT: 'Offline Start PIN',
    CHOOSE_MANUAL_PIN_HINT: 'Start PIN',
    CHOOSE_EXAM_BUTTON: 'TAKE CHOSEN EXAM',
    CHOOSE_LOGOUT_BUTTON: 'LOGOUT',
    CHOOSE_PROCTOR_BUTTON: 'START PROCTORING',
    CHOOSE_EXAM_HINT: 'Choose exam to take...',
    CHOOSE_WAITING_TO_START: 'Exams waiting to start',
    CHOOSE_ALREADY_STARTED: 'Exams already started',

    ERROR_NETWORK: 'Network error',
    ERROR_USER_PASS: 'Login ID or Password is incorrect',
    ERROR_CONNECTION_TIMEOUT: 'Connection timeout',
    ERROR_HTTP: ({code}) => `Server error ${code ? ` (HTTP ${code})` : ''}`,
    ERROR_PROXY: 'Server busy, please try again.',
    ERROR_UNKNOWN: ({message}) => `Error ${message ? ` [${message}]` : ''}`,
    ERROR_STORAGE: "You have run out of storage space. Please make more space available, or move to a different device.",
    ERROR_PIN: 'Incorrect PIN, or wrong exam chosen',
    ERROR_CANDIDATE_NOT_FOUND: 'User ID entered incorrectly, or is not registered for this exam.',
    ERROR_RESEND: ({err}) => `${err}\n\nUnable to contact server. You can continue the exam offline and responses will be stored locally on this device, but they will need to be submitted to the server before you can finish the exam. You will not be able to see any responses previously submitted from a different device. If the network recovers all responses will be saved to the server automatically.\n`,

    DOWNLOAD_TITLE: ({downloadCount}) => `Downloading ${downloadCount} exam${plural(downloadCount) ? 's' : ''}, please wait...`,
    DOWNLOAD_DESCRIPTION: ({freeSpace}) => `<p>Downloading can take several minutes depending on your network connectivity</p>${freeSpace}`,

    PREPARING_TITLE: 'Preparing exam, please wait...',
    PREPARING_DESCRIPTION: ({freeSpace}) => `<p>Preparing can take several minutes depending on your computer specification<p>${freeSpace}`,

    CONTROL_OVERVIEW: 'OVERVIEW',
    CONTROL_LANGUAGE: 'LANGUAGE',
    CONTROL_FINISH: 'FINISH',
    CONTROL_CALCULATOR: 'CALCULATOR',
    CONTROL_COLOUR: 'COLOUR',
    CONTROL_VERSION: 'VERSION',
    CONTROL_USERID: 'USER ID',
    CONTROL_PREVIOUS: 'PREVIOUS',
    CONTROL_NEXT: 'NEXT',
    CONTROL_PREVIOUS_CASE: 'PREV CASE',
    CONTROL_NEXT_CASE: 'NEXT CASE',
    CONTROL_PREVIOUS_CANDIDATE: 'PREV CANDIDATE',
    CONTROL_NEXT_CANDIDATE: 'NEXT CANDIDATE',
    CONTROL_PREVIOUS_STATION: 'PREV STATION',
    CONTROL_NEXT_STATION: 'NEXT STATION',
    CONTROL_NOTES: 'NOTES',
    CONTROL_CONNECT: 'CONNECT',
    CONTROL_CONNECTING: 'CONNECTING',
    CONTROL_DISCONNECT: 'DISCONNECT',
    CONTROL_DISCONNECTING: 'DISCONNECTING',
    CONTROL_ID_VALIDATE: ({id}) => `VALIDATE ${id}`,
    CONTROL_ID_INVALIDATE: ({id}) => `INVALIDATE ${id}`,
    CONTROL_MUTE: 'MUTE',
    CONTROL_UNMUTE: 'UNMUTE',
    CONTROL_CAMERA: 'CAMERA',
    CONTROL_SPEAKERS: 'SPEAKERS',
    CONTROL_MICROPHONE: 'MICROPHONE',
    CONTROL_HIGHLIGHT: '(UN)HIGHLIGHT',

    TITLE_ROUND: 'round',
    TITLE_STATION: 'station',
    TITLE_CIRCUIT: 'circuit',
    TITLE_CASE: ({n, m}) => `case&nbsp;<b>${n}</b>&nbsp;of&nbsp;<b>${m}</b>`,
    TITLE_CONNECT_TO: ({factor}) => `connect&nbsp;to&nbsp;${factor}`,

    NOTIFICATION_CONNECT: '<center>Click the <b>CONNECT</b> button above to start.</center>',
    NO_CANDIDATE: 'No candidate for this round.',
    REST_STATION: 'This is a rest station.',

    MEETING_POOR_CONNECTION: 'Poor connection: you may have audio and video quality issues.',
    MEETING_STATUS_ERROR: ({err}) => `Meeting exit status: ${err}`,

    TIMER_REMAINING: 'TIME REMAINING',
    TIMER_ELAPSED: 'ELAPSED TIME',
    TIMER_ROUND_REMAINING: 'ROUND REMAINING',

    OVERVIEW_TITLE: 'Overview',
    OVERVIEW_NO_CANDIDATE: 'No Candidate',
    RESOURCE_RESTRICTED_SHOW: 'Show',
    RESOURCE_RESTRICTED_HIDE: 'Hide',

    ANSWER_OPTIONAL: 'optional',
    ANSWER_REQUIRED: 'required',
    ANSWER_TITLE: 'Answer',
    ANSWER_FLAG_TIP: 'Flag this question',
    SBA_ANSWER_TIP: 'Select this answer',
    SBA_ELIMINATE_TIP: 'Eliminate this answer',
    NOT_ANSWERED: 'Not Answered',

    PAUSED_TITLE: 'Exam paused',
    PAUSED_DESCRIPTION: '<p>The exam has been <strong>paused</strong> remotely by an administrator.<p>',

    STOPPED_TITLE: 'Exam Finished',
    STOPPED_CLEANUP: '<p>Cleaning up, please wait...<p>',

    MARKSHEET_INVALID: ({invalid}) => `The following mandatory criteria need a response:<br><br><b>${invalid}</b><br><br><div style="text-align:left"><b>OK</b> - to leave this marksheet.<br><b>CANCEL</b> - to stay on this marksheet and see which criteria are missing.</div>`,

    FINISH_TITLE: 'Ready to finish?',
    FINISH_DESCRIPTION: 'You should click <i>Submit exam</i> below before closing the tab or window.</p>',
    FINISH_ANSWERED: '<p>You have answered all the questions.</p>',
    FINISH_UNANSWERED: ({totalUnanswered}) => `<p>You have <strong>${totalUnanswered}</strong> unanswered question${plural(totalUnanswered) ? 's' : ''}.</p>`,
    FINISH_MANDATORY_UNANSWERED: ({totalUnanswered, mandatoryUnanswered}) => `<p>You have <strong>${totalUnanswered}</strong> unanswered question${plural(totalUnanswered) ? 's' : ''} (${mandatoryUnanswered} mandatory).</p>`,
    FINISH_SUBMITTED: '<p><b>All of your answers have been submitted to the server.</b></p>',
    FINISH_UNSUBMITTED: ({unsubmitted}) => `<p>You have <strong>${unsubmitted}</strong> answer${plural(unsubmitted) ? 's' : ''} that ${plural(unsubmitted) ? 'are' : 'is'} not submitted to the server.</p><p>It is safe to continue the exam as answers will be stored on your device.</p>`,
    FINISH_CONTINUE: 'Continue exam',
    FINISH_NOW: 'Submit exam',

    ALERT_TITLE: 'Response Required',
    ALERT_OK: 'OK',
    ALERT_CANCEL: 'CANCEL',
    ALERT_RETRY: 'RETRY',
    ALERT_QUIT: 'QUIT',
    ALERT_IGNORE: "CANCEL",

    SCREEN_SHARE_PERMISSION: 'For this exam you need to <i>allow</i> your screen to be shared with the examiner. You must share the whole screen, so if you are given options you should select "whole screen", "entire screen" or equivalent.',
    SCREEN_SHARE_FAILED: '<b>Screen sharing is currently <i>blocked</i>.</b>',

    VIEWER_RESET: 'RESET VIEW',
    VIEWER_SELECT: 'SELECT CLICK ACTION',
    VIEWER_PREV: 'PREVIOUS PAGE',
    VIEWER_NEXT: 'NEXT PAGE',
    VIEWER_FULLSCREEN: 'TOGGLE FULLSCREEN',
    VIEWER_CLOSE: 'CLOSE VIEWER',

    LOGOUT_MESSAGE: '<p>You are currently logged out, click below to login.</p>',
    LOGIN_AGAIN: 'LOGIN',
    P4B_BACKGROUNDED: 'This tab has been suspended because another Practique exam tab has been active. Click OK to resume.',
});
