//import { asm, nextValidHeapSize } from '../static/asm-render';
import {
    DataSet,
    parseDicom,
    createJPEGBasicOffsetTable,
    readEncapsulatedImageFrame,
    readEncapsulatedPixelDataFromFragments,
    Element as PixelDataElement,
} from 'dicom-parser';
import { Canvas, createCanvas, createImageData, CanvasRenderingContext2D } from 'canvas';
import * as jpeg from 'jpeg-lossless-decoder-js';
import { Img, IType, Renderer, registerRendererType, Frame} from '@p4b/image-base';
import { Transform } from '@p4b/utils';
//import { alertModal } from '@p4b/utils-progress';


declare interface Tile {
    items: Int16Array | Uint16Array | Int8Array | Uint8Array;
}

declare class JpxImage {
    public parse(data: Uint8Array): void;
    public failOnCorruptedImage: boolean;
    public width: number;
    public height: number;
    public componentsCount: number;
    public tiles: Tile[];
}

function lut8unsigned(pmin: number, pmax: number, pscl: number, lut: Uint8Array): void {
    let x = 0;
    let y = (pmin < 0) ? (-pmin) * pscl : 0;
    while (x < pmin) {
        lut[x++] = 0;
    }
    while (x < pmax) {
        lut[x++] = y;
        y += pscl;
    }
    while (x < 256) {
        lut[x++] = 255;
    }
}

function lut8inverted(pmin: number, pmax: number, pscl: number, lut: Uint8Array): void {
    let x = 255;
    let y = (pmin < 0) ? 255 + pmin * pscl : 255;
    while (x < pmin) {
        lut[x++] = 255;
    }
    while (x < pmax) {
        lut[x++] = y;
        y -= pscl;
    }
    while (x < 256) {
        lut[x++] = 0;
    }
}

function lut16unsigned(pmin: number, pmax: number, pscl: number, lut: Uint8Array): void {
    let x = 0;
    let y = (pmin < 0) ? (-pmin) * pscl : 0;
    while (x < pmin) {
        lut[x++] = 0;
    }
    while (x < pmax) {
        lut[x++] = y;
        y += pscl;
    }
    while (x < 65536) {
        lut[x++] = 255;
    }
}

function lut16inverted(pmin: number, pmax: number, pscl: number, lut: Uint8Array): void {
    let x = 0;
    let y = (pmin < 0) ? 255 + pmin * pscl : 255;
    while (x < pmin) {
        lut[x++] = 255;
    }
    while (x < pmax) {
        lut[x++] = y;
        y -= pscl;
    }
    while (x < 65536) {
        lut[x++] = 0;
    }
}

function lut16signed(pmin: number, pmax: number, pscl: number, lut: Uint8Array): void {
    let x = 0;
    let y = (pmin < -32768) ? (-32768 - pmin) * pscl : 0;
    while (x - 32768 < pmin) {
        lut[x++] = 0;
    }
    while (x - 32768 < pmax) {
        lut[x++] = y;
        y += pscl;
    }
    while (x < 65536) {
        lut[x++] = 255;
    }
}

function lut16signedInverted(pmin: number, pmax: number, pscl: number, lut: Uint8Array): void {
    let x = 0;
    let y = (pmin < -32768) ? 255 + (32768 + pmin) * pscl : 255;
    while (x - 32768 < pmin) {
        lut[x++] = 255;
    }
    while (x - 32768 < pmax) {
        lut[x++] = y;
        y -= pscl;
    }
    while (x < 65536) {
        lut[x++] = 0;
    }
}

function applyLut8(src: Uint8Array, lut: Uint8Array, dst: Uint32Array, n: number): void {
    for (let i = 0; i < n; ++i) {
        const p = lut[src[i]];
        dst[i] = p | p << 8 | p << 16 | 0xff000000;
    }
}

function applyLut16(src: Uint16Array, lut: Uint8Array, dst: Uint32Array, n: number): void {
    for (let i = 0; i < n; ++i) {
        const p = lut[src[i]];
        dst[i] = p | p << 8 | p << 16 | 0xff000000;
    }
}

function applyLut16signed(src: Int16Array, lut: Uint8Array, dst: Uint32Array, n: number): void {
    for (let i = 0; i < n; ++i) {
        const p = lut[src[i] + 32768];
        dst[i] = p | p << 8 | p << 16 | 0xff000000;
    }
}

function applyLut8rgb(src: Uint8Array, lut: Uint8Array, dst: Uint32Array, n: number): void {
    let p = 0;
    for (let i = 0; i < n; ++i) {
        dst[i] = lut[src[p++]] | lut[src[p++]] << 8 | lut[src[p++]] << 16 | 0xff000000;
    }
}

function applyLut8rgba(src: Uint8Array, lut: Uint8Array, dst: Uint32Array, n: number): void {
    let p = 0;
    for (let i = 0; i < n; ++i) {
        dst[i] = lut[src[p++]] | lut[src[p++]] << 8 | lut[src[p++]] << 16 | 0xff000000; ++p; //src[p++];
    }
}

type RenderFn = (pmin: number, pmax: number, pscl: number, n: number, src: ArrayBuffer) => Uint8ClampedArray;
type RenderFactory = (lut: ArrayBuffer, dst: ArrayBuffer) => RenderFn;

interface RenderFactories {
    render8: RenderFactory;
    render8inverted: RenderFactory;
    render16: RenderFactory;
    render16inverted: RenderFactory;
    render16signed: RenderFactory;
    render16signedInverted: RenderFactory;
    render8rgb: RenderFactory;
    render8rgba: RenderFactory;
    unsupported: (dst: ArrayBuffer) => (pmin: number, pmax: number, pscl: number, n: number, src: ArrayBuffer) => Uint8ClampedArray;
}

const renderFns: RenderFactories = {
    render8: (lut, dst) => {
        const lut8 = new Uint8Array(lut);
        const dst32 = new Uint32Array(dst);
        return (pmin, pmax, pscl, n, src) => {
            lut8unsigned(pmin, pmax, pscl, lut8);
            applyLut8(new Uint8Array(src), lut8, dst32, n);
            return new Uint8ClampedArray(dst, 0, 4 * n);
        };
    },
    render8inverted: (lut, dst) => {
        const lut8 = new Uint8Array(lut);
        const dst32 = new Uint32Array(dst);
        return (pmin, pmax, pscl, n, src) => {
            lut8inverted(pmin, pmax, pscl, lut8);
            applyLut8(new Uint8Array(src), lut8, dst32, n);
            return new Uint8ClampedArray(dst, 0, 4 * n);
        };
    },
    render16: (lut, dst) => {
        const lut8 = new Uint8Array(lut);
        const dst32 = new Uint32Array(dst);
        return (pmin, pmax, pscl, n, src) => {
            lut16unsigned(pmin, pmax, pscl, lut8);
            applyLut16(new Uint16Array(src), lut8, dst32, n);
            return new Uint8ClampedArray(dst, 0, 4 * n);
        };
    },
    render16inverted: (lut, dst) => {
        const lut8 = new Uint8Array(lut);
        const dst32 = new Uint32Array(dst);
        return (pmin, pmax, pscl, n, src) => {
            lut16inverted(pmin, pmax, pscl, lut8);
            applyLut16(new Uint16Array(src), lut8, dst32, n);
            return new Uint8ClampedArray(dst, 0, 4 * n);
        };
    },
    render16signed: (lut, dst) => {
        const lut8 = new Uint8Array(lut);
        const dst32 = new Uint32Array(dst);
        return (pmin, pmax, pscl, n, src) => {
            lut16signed(pmin, pmax, pscl, lut8);
            applyLut16signed(new Int16Array(src), lut8, dst32, n);
            return new Uint8ClampedArray(dst, 0, 4 * n);
        };
    },
    render16signedInverted: (lut, dst) => {
        const lut8 = new Uint8Array(lut);
        const dst32 = new Uint32Array(dst);
        return (pmin, pmax, pscl, n, src) => {
            lut16signedInverted(pmin, pmax, pscl, lut8);
            applyLut16signed(new Int16Array(src), lut8, dst32, n);
            return new Uint8ClampedArray(dst, 0, 4 * n);
        };
    },
    render8rgb: (lut, dst) => {
        const lut8 = new Uint8Array(lut);
        const dst32 = new Uint32Array(dst);
        return (pmin, pmax, pscl, n, src) => {
            lut8unsigned(pmin, pmax, pscl, lut8);
            applyLut8rgb(new Uint8Array(src), lut8, dst32, n);
            return new Uint8ClampedArray(dst, 0, 4 * n);
        };
    },
    render8rgba: (lut, dst) => {
        const lut8 = new Uint8Array(lut);
        const dst32 = new Uint32Array(dst);
        return (pmin, pmax, pscl, n, src) => {
            lut8unsigned(pmin, pmax, pscl, lut8);
            applyLut8rgba(new Uint8Array(src), lut8, dst32, n);
            return new Uint8ClampedArray(dst, 0, 4 * n);
        };
    },
    unsupported: (dst) => {
        const dst32 = new Uint32Array(dst);
        return (_pmin, _pmax, _pscl, n) => {
            for (let i = 0; i < n; ++i) {
                dst32[i] = 0xff0000ff;
            }
            return new Uint8ClampedArray(dst, 0, 4 * n);
        };
    },
};


//------------------------------------------------------------------------
// Support for DICOM format images.

// BEGIN: PATCH DICOM PARSERinnerWidth

/*
dicomParser.isEncapsulated = function(_element, byteStream) {
    switch(byteStream.transferSyntax) {
        case '1.2.840.10008.1.2.4.57':
        case '1.2.840.10008.1.2.4.70':
        case '1.2.840.10008.1.2.4.90':
            const tag = dicomParser.readTag(byteStream);
            byteStream.seek(-4);
            return tag === 'xfffee000';
        default:
            return false;
    }
}
*/

// END: PATCH DICOM PARSER

function getData(dataSet: DataSet, pixelDataElement: PixelDataElement, len: number, frame: number, frameCount: number): ArrayBuffer {
    if (pixelDataElement && pixelDataElement.encapsulatedPixelData) {
        if (!pixelDataElement.basicOffsetTable?.length) {
            if (frameCount != pixelDataElement.fragments?.length) {
                const basicOffsetTable = createJPEGBasicOffsetTable(dataSet, pixelDataElement);
                const data = readEncapsulatedImageFrame(dataSet, pixelDataElement, frame, basicOffsetTable);
                return data.buffer.slice(data.byteOffset, data.byteOffset + data.byteLength);
            }
            const data = readEncapsulatedPixelDataFromFragments(dataSet, pixelDataElement, frame);
            return data.buffer.slice(data.byteOffset, data.byteOffset + data.byteLength);
        }
        const data = readEncapsulatedImageFrame(dataSet, pixelDataElement, frame);
        return data.buffer.slice(data.byteOffset, data.byteOffset + data.byteLength);
    }

    const offset = frame * len + (pixelDataElement.dataOffset | 0);
    if (len !== pixelDataElement.length) {
        console.warn('pixelDataElement.length <> rows * cols * bytes * samplesPerPixel');
    }
    return dataSet.byteArray.buffer.slice(offset, offset + len);
}

async function getPixelData(
    dataSet: DataSet,
    dicom: DicomImg,
    transferSyntax: string,
    bytes: number,
    signed: boolean,
    len: number,
    frame: number,
    frameCount: number,
): Promise<ArrayBuffer> {
    const pixelDataElement = dataSet.elements.x7fe00010;
    const buffer = getData(dataSet, pixelDataElement, len, frame, frameCount);
    switch(transferSyntax) {
        case '1.2.840.10008.1.2.4.50': {
            const img = await new Promise<HTMLImageElement>((resolve, reject) => {
                const img = new Image();
                img.onload = () => resolve(img);
                img.onerror = reject;
                img.src = (URL || webkitURL).createObjectURL(new Blob([buffer]));
            });
            const canvas = createCanvas(img.width, img.height);
            const cxt = canvas.getContext('2d', { alpha: false });
            if (!cxt) {
                throw 'null context';
            }
            cxt.imageSmoothingEnabled = true;
            cxt.imageSmoothingQuality = 'high';
            cxt.drawImage(img, 0, 0, canvas.width, canvas.height);
            (URL || webkitURL).revokeObjectURL(img.src);
            const data = cxt.getImageData(0, 0, canvas.width, canvas.height).data;
            if (dicom.samplesPerPixel === 1) {
                // Greyscale JPEG
                const buf = new Uint8Array(data.byteLength >> 2);
                for (let x = 0, y = 0; x < data.byteLength; x += 4) {
                    buf[y++] = data[x];
                }
                return buf;
            } else {
                // Colour JPEG
                dicom.samplesPerPixel = 3;
                dicom.photometricInterpretation = 'RGB';
                const buf = new Uint8Array((data.byteLength >> 2) * 3);
                for (let x = 0, y = 0; x < data.byteLength; x += 4) {
                    buf[y++] = data[x];
                    buf[y++] = data[x + 1];
                    buf[y++] = data[x + 2];
                }
                return buf;
            }
        }
        case '1.2.840.10008.1.2.4.57':
        case '1.2.840.10008.1.2.4.70': {
            console.debug('JPEG');
            const decoder = new jpeg.lossless.Decoder();
            return decoder.decompress(buffer, 0, buffer.byteLength);
        }
        case '1.2.840.10008.1.2.4.90': {
            console.info('JP2');
            const data = new Uint8Array(buffer);
            console.info('DATA_SIZE', data.length);
            const signature = [data[0].toString(16), data[1].toString(16), data[2].toString(16), data[3].toString(16),
                data[4].toString(16), data[5].toString(16), data[6].toString(16), data[7].toString(16)].join(' ');
            console.info('IS_J2K: ', signature);
            const decoder = new JpxImage();
            decoder.parse(data);
            if (decoder.failOnCorruptedImage) {
                throw new Error('Transfer Syntax 1.2.840.10008.1.2.4.90: JP2 image is corrupted');
            }
            const decoded = decoder.tiles[0].items;
            return decoded.buffer;
        }
        case '1.2.840.10008.1.2':
        case '1.2.840.10008.1.2.1':
	    return buffer;
        case '1.2.840.10008.1.2.2':
            if (bytes === 2) {
                const data = new DataView(buffer);
                if (signed) {
                    for (let x = 0; x < data.byteLength; x += 2) {
                        const y = data.getInt16(x, false);
                        data.setInt16(x, y, true);
                    }
                } else {
                    for (let x = 0; x < data.byteLength; x += 2) {
                        const y = data.getUint16(x, false);
                        data.setUint16(x, y, true);
                    }
                }
            } else if (bytes === 4) {
                const data = new DataView(buffer);
                if (signed) {
                    for (let x = 0; x < data.byteLength; x += 4) {
                        const y = data.getInt32(x, false);
                        data.setInt32(x, y, true);
                    }
                } else {
                    for (let x = 0; x < data.byteLength; x += 4) {
                        const y = data.getUint32(x, false);
                        data.setUint32(x, y, true);
                    }
                }
            } else if(bytes !== 1) {
                console.warn(`Unsupported byte size for big-endian ${bytes}`);
            }
            return buffer;
        default:
            console.warn(`unknown transfer-syntax: ${transferSyntax}`);
            return buffer;
    }
}

function rescale(rescaleIntercept: number, rescaleSlope: number, bytes: number, data: ArrayBuffer, signed: boolean, mask: number, signBit: number): boolean {
    if (
        rescaleIntercept !== undefined && rescaleSlope !== undefined
        && ((rescaleIntercept != 0.0) || (rescaleSlope != 1.0))
    ) {
        const iarray = (signed) ? (
            (bytes === 1) ? new Int8Array(data):
                (bytes === 2) ? new Int16Array(data) :
                    (bytes === 4) ? new Int32Array(data) :
                        null
        ) : (
            (bytes === 1) ? new Uint8Array(data) :
                (bytes === 2) ? new Uint16Array(data) :
                    (bytes === 4) ? new Uint32Array(data) :
                        null
        );
        const oarray = (
            (bytes === 1) ? new Int8Array(data) :
                (bytes === 2) ? new Int16Array(data) :
                    (bytes === 4) ? new Int32Array(data) :
                        null
        );
        const tmin = (bytes === 1) ? -0x80 :
            (bytes === 2) ? -0x8000 :
                (bytes === 4) ? -0x80000000 :
                    0;
        const tmax = (bytes === 1) ? 0x7f :
            (bytes === 2) ? 0x7fff :
                (bytes === 4) ? 0x7fffffff :
                    0;
        if (iarray != null && oarray != null) {
            if (signed) {
                for (let i = 0; i < iarray.length; ++i) {
                    const tmp = (((iarray[i] & mask) ^ signBit) - signBit) * rescaleSlope + rescaleIntercept;
                    if (tmp < tmin) {
                        oarray[i] = tmin;
                    } else if (tmp > tmax) {
                        oarray[i] = tmax;
                    } else {
                        oarray[i] = tmp;
                    }
                }
            } else {
                for (let i = 0; i < iarray.length; ++i) {
                    const tmp = (iarray[i] & mask) * rescaleSlope + rescaleIntercept;
                    if (tmp < tmin) {
                        oarray[i] = tmin;
                    } else if (tmp > tmax) {
                        oarray[i] = tmax;
                    } else {
                        oarray[i] = tmp;
                    }
                }
            }
            return true;
        } else {
            console.error('UNSUPPORTED WORD SIZE IN RESCALE');
            return signed;
        }
    } else {
        const iarray = (signed) ? (
            (bytes === 1) ? new Int8Array(data) :
                (bytes === 2) ? new Int16Array(data) :
                    (bytes === 4) ? new Int32Array(data) :
                        null
        ) : (
            (bytes === 1) ? new Uint8Array(data) :
                (bytes === 2) ? new Uint16Array(data) :
                    (bytes === 4) ? new Uint32Array(data) :
                        null
        );
        if (iarray != null) {
            if (signed) {
                for (let i = 0; i < iarray.length; ++i) {
                    iarray[i] = ((iarray[i] & mask) ^ signBit) - signBit;
                }
            } else {
                for (let i = 0; i < iarray.length; ++i) {
                    iarray[i] = iarray[i] & mask;
                }
            }
        } else {
            console.error('UNSUPPORTED WORD SIZE IN MASKING');
        }
        return signed
    }
}

interface Overlay {
    top: number;
    left: number;
    width: number;
    height: number;
    byteLength: number;
}

export interface DicomFrame extends Frame {
    rows: number,
    cols: number,
    sortKey: number;
    overlayRects: Overlay[];
    overlayBitmaps: (ArrayBuffer|undefined)[];
}

export interface DicomImg extends Img {
    rows: number;
    cols: number;
    bytes: number;
    samplesPerPixel: number;
    signed: boolean;
    originalSigned: boolean;
    mask: number;
    signBit: number;
    unsignedMask: number;
    photometricInterpretation: string;
    planarConfiguration: number;
    modality: string;
    pixelSpacing: [number, number];
    rescaleType?: string;
    windowed: boolean;
    windowCenter: number;
    windowWidth: number;
    frames: DicomFrame[];
}

export async function makeDicom(data: ArrayBuffer, id = ''): Promise<DicomImg> {
    const dataSet = parseDicom(new Uint8Array(data));
    const signed = dataSet.uint16('x00280103') === 1
    , highBit = Math.pow(2, dataSet.uint16('x00280101')) | 0
    , signBit = highBit >> 1
    , windowCenter = dataSet.floatString('x00281050') ?? 0
    , windowWidth = dataSet.floatString('x00281051') ?? 0
    ;

    //console.debug(`INITIAL_WINDOW ${windowCenter}~${windowWidth}`);

    const dicom = {
        id,
        iType: IType.Dicom,
        caption: '',
        rows: 0,
        cols: 0,
        bytes: dataSet.uint16('x00280100') >> 3,
        samplesPerPixel: dataSet.uint16('x00280002') | 0,
        signed,
        originalSigned: signed,
        mask: highBit - 1,
        signBit,
        unsignedMask: (signBit - 1),
        photometricInterpretation: dataSet.string('x00280004') || 'MONOCHROME2',
        planarConfiguration: dataSet.uint16('x00280006') | 0,
        modality: dataSet.string('x00080060'),
        pixelSpacing: [dataSet.floatString('x00280030', 1), dataSet.floatString('x00280030', 0)] as [number, number],
        rescaleType: dataSet.string('x00281054'),
        windowed: windowWidth >= 1,
        windowCenter,
        windowWidth,
        frameCount: 0,
        frames: [],
    };

    await addFrame(dicom, dataSet);
    return dicom;
}

export async function addDicom(dicom: DicomImg, data: ArrayBuffer): Promise<void> {
    await addFrame(dicom, parseDicom(new Uint8Array(data)));
}

function findFirstGreater(frames: DicomFrame[], sortKey: number): number {
    for (let i = 0; i < frames.length; ++i) {
        if (frames[i].sortKey > sortKey) {
            return i;
        }
    }
    return frames.length;
}

async function addFrame(dicom: DicomImg, dataSet: DataSet): Promise<void> {
    if (!dataSet.elements.x7fe00010) {
        console.error('pixel data element not found');
        return;
    }

    const samplesPerPixel =  dataSet.uint16('x00280002') | 0
        , photometricInterpretation = dataSet.string('x00280004') || "MONOCHROME2"
        , planarConfiguration = dataSet.uint16('x00280006') | 0
        , bytes = dataSet.uint16('x00280100') >> 3
        , highBit = Math.pow(2, dataSet.uint16('x00280101')) | 0
        , mask = highBit - 1
        , signed = (dataSet.uint16('x00280103') === 1)
        ;

    if (dicom.frames.length === 0) {
        dicom.bytes = bytes;
        dicom.samplesPerPixel = samplesPerPixel;
        dicom.signed = signed;
        dicom.originalSigned = signed;
        dicom.mask = mask;
        dicom.signBit = highBit >> 1;
        dicom.unsignedMask = dicom.signBit - 1;
        dicom.photometricInterpretation = photometricInterpretation;
        dicom.planarConfiguration = planarConfiguration;
        dicom.modality = dataSet.string('x00080060');
        dicom.pixelSpacing = [dataSet.floatString('x00280030', 1), dataSet.floatString('x00280030', 0)] as [number, number];
        dicom.rescaleType = dataSet.string('x00281054');
        dicom.windowCenter = dataSet.floatString('x00281050') ?? 0;
        dicom.windowWidth = dataSet.floatString('x00281051') ?? 0;
        dicom.windowed = dicom.windowWidth >= 1;
    } else {
        if (dicom.bytes !== bytes) {
            console.error("bytes mismatch", dicom.bytes, "<>", bytes);
            return;
        }

        if (dicom.mask !== mask) {
            console.error("bitmask mismatch", dicom.mask, "<>", mask);
            return;
        }

        if (dicom.originalSigned !== signed) {
            console.error("signed mismatch", dicom.originalSigned, "<>", signed);
            return;
        }

        if (dicom.samplesPerPixel !== samplesPerPixel) {
            console.error("samples per pixel mismatch", dicom.samplesPerPixel, "<>", samplesPerPixel);
            return;
        }

        if (dicom.photometricInterpretation !== photometricInterpretation) {
            console.error("photometric interpretation mismatch",
                dicom.photometricInterpretation, "<>", photometricInterpretation);
            return;
        }

        if (dicom.planarConfiguration !== planarConfiguration) {
            console.error("planar configuration mismatch", dicom.planarConfiguration, "<>", planarConfiguration);
            return;
        }
    }

    const windowCenter = dataSet.floatString('x00281050') ?? 0
    , windowWidth = dataSet.floatString('x00281051') ?? 0
    , rescaleIntercept = dataSet.floatString('x00281052')
    , rescaleSlope = dataSet.floatString('x00281053')
    , transferSyntax = dataSet.string('x00020010')
    , minPixel = dataSet.uint16('x00280106')
    , maxPixel = dataSet.uint16('x00280107')
    , rows = dataSet.uint16('x00280010') | 0
    , cols = dataSet.uint16('x00280011') | 0
    , petImageIndex = dataSet.uint16('x00541330')
    , x1 = dataSet.floatString('x00200037', 0)
    , y1 = dataSet.floatString('x00200037', 1)
    , z1 = dataSet.floatString('x00200037', 2)
    , x2 = dataSet.floatString('x00200037', 3)
    , y2 = dataSet.floatString('x00200037', 4)
    , z2 = dataSet.floatString('x00200037', 5)
    , v1 = x1 * x1 + x2 * x2
    , v2 = y1 * y1 + y2 * y2
    , v3 = z1 * z1 + z2 * z2
    , sortIndex = ((v1 <= v2 && v1 <= v3) ? 0 :
        (v2 <= v1 && v2 <= v3) ? 1 :
        (v3 <= v1 && v3 <= v2) ? 2 : -1)
    , sortKey = (petImageIndex != undefined) ? petImageIndex : ((sortIndex >= 0) ?
        (-dataSet.floatString('x00200032', sortIndex)) :
        dataSet.intString('x00200013'))
    , frameCount = dataSet.intString('x00280008') || 1
    , len = rows * cols * bytes * samplesPerPixel
    , insertIndex = findFirstGreater(dicom.frames, sortKey)
    ;

    let min = minPixel;
    let max = maxPixel;
    if (windowWidth >= 1) {
        const r = windowWidth / 2
        min = windowCenter - r;
        max = windowCenter + r;
    }

    if (min != undefined && max != undefined) {
        const r = dicom.windowWidth / 2;
        min = Math.min(dicom.windowCenter - r, min);
        max = Math.max(dicom.windowCenter + r, max);
        dicom.windowCenter = (min + max) / 2;
        dicom.windowWidth = (max - min);
        console.debug(`WINDOW RANGE ${windowCenter}±${windowWidth} ${min}-${max}`);
    }

    console.log('FRAMES', frameCount);

    for (let i = 0; i < frameCount; ++i) {
        const px = await getPixelData(dataSet, dicom, transferSyntax, bytes, dicom.originalSigned, len, i, frameCount);
        dicom.signed = rescale(rescaleIntercept, rescaleSlope, bytes, px, dicom.originalSigned, dicom.mask, dicom.signBit);
        const {overlayRects, overlayBitmaps} = getOverlay(dataSet);
        dicom.frames.splice(insertIndex, 0, {
            data: px,
            dataSize: px.byteLength,
            rows,
            cols,
            sortKey,
            overlayRects,
            overlayBitmaps,
        });
        if (!dicom.windowed && (minPixel == undefined || maxPixel == undefined)) {
            dicomMinMax(dicom, insertIndex);
        }
    }

    dicom.frameCount += frameCount;

    if (rows > dicom.rows) {
        dicom.rows = rows;
    }

    if (cols > dicom.cols) {
        dicom.cols = cols;
    }
}

export function dicomMinMax(dicom: DicomImg, index: number): void {
    const frame = dicom.frames[index];
    const thisData = frame.data;

    if (thisData == null) {
        throw('THIS_DATA is null');
    }

    const inData = (dicom.signed) ? (
        (dicom.bytes === 1) ? new Int8Array(thisData):
            (dicom.bytes === 2) ? new Int16Array(thisData) :
                (dicom.bytes === 4) ? new Int32Array(thisData) :
                    null
    ) : (
        (dicom.bytes === 1) ? new Uint8Array(thisData) :
            (dicom.bytes === 2) ? new Uint16Array(thisData) :
                (dicom.bytes === 4) ? new Uint32Array(thisData) :
                    null
    );

    if (inData === null) {
        throw('Unsupported byte size');
    }

    const cols = frame.cols
        , rows = frame.rows
        , count = cols * rows
        ;
    let min, max;

    if (dicom.windowCenter && dicom.windowWidth) {
        min = dicom.windowCenter - (dicom.windowWidth / 2);
        max = dicom.windowCenter + (dicom.windowWidth / 2);
    } else if (dicom.signed) {
        max = dicom.mask;
        min = dicom.unsignedMask;
    } else {
        max = 0;
        min = dicom.mask;
    }

    for (let i = 0; i < count; ++i) {
        const p = inData[i];
        if (p > max) {
            max = p;
        }
        if (p < min) {
            min = p;
        }
    }

    //console.debug(`CALC_MINMAX ${min}-${max}`);
    dicom.windowCenter = (min + max) / 2.0;
    dicom.windowWidth = max - min;
}

function getOverlay(dataSet: DataSet): {overlayRects: Overlay[], overlayBitmaps: ArrayBuffer[]} {
    const pixelDataElement = dataSet.elements.x60003000
        , top = dataSet.uint16('x60000050', 0) - 1
        , left = dataSet.uint16('x60000050', 1) - 1
        , height = dataSet.uint16('x60000010')
        , width = dataSet.uint16('x60000011')
        , transferSyntax = dataSet.string('x00020010')
        , data = pixelDataElement
            ? new DataView(dataSet.byteArray.buffer, pixelDataElement.dataOffset, pixelDataElement.length)
            : undefined
        ;

    if (top != undefined && left != undefined && height != undefined && width != undefined && data != undefined) {
        const bitmap = new Uint16Array(pixelDataElement.length / 2);
        const littleEndian = transferSyntax !== '1.2.840.10008.1.2.2';

        for (let i = 0, j = 0; i < pixelDataElement.length; i += 2) {
            bitmap[j++] = data.getUint16(i, littleEndian);
        }

        return {
            overlayRects: [{top, left, width, height, byteLength: bitmap.byteLength}],
            overlayBitmaps: [bitmap.buffer],
        };
    } else {
        return {overlayRects: [], overlayBitmaps: []};
    }
}


export class DicomRenderer implements Renderer {
    //private readonly heap: ArrayBuffer;
    //private readonly asm: (pmin: number, pmax: number, pscl: number, n: number) => void;
    private readonly renderer: RenderFn;
    private canvas?: Canvas;

    public index: number;
    public brightness: number;
    public contrast: number;
    public readonly img: DicomImg;

    public constructor(img: DicomImg) {
        //console.debug('PHOTOMETRIC_INTERPRETATION', img.photometricInterpretation);
        const n = img.rows * img.cols; //Math.max.apply(this, img.sizes.map(([x, y]): number => x * y));
        if (img.photometricInterpretation === 'MONOCHROME1' &&
            img.samplesPerPixel === 1 &&
            img.bytes === 1 &&
            !img.signed
        ) {
            console.debug(`${img.caption}: 8bit unsigned inverted`);
            //this.asm = rmod.render8inverted;
            this.renderer = renderFns.render8inverted(new ArrayBuffer(256), new ArrayBuffer(4 * n));
        } else if (img.photometricInterpretation === 'MONOCHROME1' &&
            img.samplesPerPixel === 1 &&
            img.bytes === 2 &&
            !img.signed
        ) {
            console.debug(`${img.caption}: 16bit unsigned inverted`);
            //this.asm = rmod.render16inverted_unsigned;
            this.renderer = renderFns.render16inverted(new ArrayBuffer(65536), new ArrayBuffer(4 * n));
        } else if (img.photometricInterpretation === 'MONOCHROME1' &&
            img.samplesPerPixel === 1 &&
            img.bytes === 2 &&
            img.signed
        ) {
            console.debug(`${img.caption}: 16bit signed inverted`);
            //this.asm = rmod.render16inverted_signed;
            this.renderer = renderFns.render16signedInverted(new ArrayBuffer(65536), new ArrayBuffer(4 * n));
        } else if (img.photometricInterpretation === 'MONOCHROME2' &&
            img.samplesPerPixel === 1 &&
            img.bytes === 1 &&
            !img.signed
        ) {
            console.debug(`${img.caption}: 8bit unsigned`);
            //this.asm = rmod.render8;
            this.renderer = renderFns.render8(new ArrayBuffer(256), new ArrayBuffer(4 * n));
        } else if (img.photometricInterpretation === 'MONOCHROME2' &&
            img.samplesPerPixel === 1 &&
            img.bytes === 2 &&
            !img.signed
        ) {
            console.debug(`${img.caption}: 16bit unsigned`);
            //this.asm = rmod.render16unsigned;
            this.renderer = renderFns.render16(new ArrayBuffer(65536), new ArrayBuffer(4 * n));
        } else if (img.photometricInterpretation === 'MONOCHROME2' &&
            img.samplesPerPixel === 1 &&
            img.bytes === 2 &&
            img.signed
        ) {
            console.debug(`${img.caption}: 16bit signed`);
            //this.asm = rmod.render16signed;
            this.renderer = renderFns.render16signed(new ArrayBuffer(65536), new ArrayBuffer(4 * n));
        } else if (img.photometricInterpretation === 'RGB' &&
            img.samplesPerPixel === 3 &&
            img.bytes === 1 &&
            !img.signed)
        {
            console.debug(`${img.caption}: 8bit rgb`);
            //this.asm = rmod.render8rgb;
            this.renderer = renderFns.render8rgb(new ArrayBuffer(256), new ArrayBuffer(4 * n));
        } else if (img.photometricInterpretation === 'RGB' &&
            img.samplesPerPixel === 4 &&
            img.bytes === 1 &&
            !img.signed)
        {
            console.debug(`${img.caption}: 8bit rgba`);
            this.renderer = renderFns.render8rgba(new ArrayBuffer(256), new ArrayBuffer(4 * n));
        } else {
            console.error(
                'UNSUPPORTED: ', img.photometricInterpretation,
                'SPP: ', img.samplesPerPixel,
                'BYTES:', img.bytes,
                'SIGNED:', img.signed
            );
            //this.asm = rmod.unsupported;
            this.renderer = renderFns.unsupported(new ArrayBuffer(4 * n));
        }
        this.img = img;
        this.index = 0;
        this.brightness = img.windowCenter;
        this.contrast = img.windowWidth;
        //this.img.cols = this.img.sizes.reduce((a, b) => Math.max(a, b[0]), 0);
        //this.img.rows = this.img.sizes.reduce((a, b) => Math.max(a, b[1]), 0);
    }

    public async init(): Promise<void> {
        // no-op
    }

    public destroy(): void {
        //this.img.data = [];
    }

    async render(): Promise<void> {
        if (!this.canvas) {
            //this.canvas = createCanvas(this.img.cols, this.img.rows);
            //const cols = this.img.sizes.reduce((a, b) => Math.max(a, b[0]), 0);
            //const rows = this.img.sizes.reduce((a, b) => Math.max(a, b[1]), 0);
            this.canvas = createCanvas(this.img.cols, this.img.rows);

        }
        const context = this.canvas.getContext('2d', {alpha: false});
        this.realRender(context);
    }

    //private act = 0;
    //private cnt = 0;

    private async realRender(context: CanvasRenderingContext2D): Promise<void> {
        const pmin = this.brightness - 0.5 - (this.contrast - 1.0) / 2.0
            , pmax = this.brightness - 0.5 + (this.contrast - 1.0) / 2.0
            , pscl = 255.0 / (pmax - pmin)
            , {rows, cols, data, overlayRects, overlayBitmaps} = this.img.frames[this.index]
            , count = rows * cols
            ;

        if (data != null) {
            context.imageSmoothingEnabled = true; //
            context.imageSmoothingQuality = 'high';
            if (cols < context.canvas.width || rows < context.canvas.height) {
                context.clearRect(0, 0, context.canvas.width, context.canvas.height);
            }
            const pixmap = this.renderer(pmin, pmax, pscl, count, data)
            , overlay = overlayRects[0]
            , bitmap = overlayBitmaps[0];
            if (overlay && bitmap) {
                const buf = new Uint32Array(pixmap.buffer)
                , bm = new Uint8Array(bitmap)
                , dy = cols - overlay.width
                ;
                for (let i = 0, x = 0, offset = overlay.top * cols + overlay.left; i < Math.ceil(overlay.width * overlay.height / 8); ++i) {
                    const word = bm[i];
                    if (word & 0x0001) buf[offset++] = 0xff00ff00; else offset++;
                    if (++x >= overlay.width) {x = 0; offset += dy}
                    if (word & 0x0002) buf[offset++] = 0xff00ff00; else offset++;
                    if (++x >= overlay.width) {x = 0; offset += dy}
                    if (word & 0x0004) buf[offset++] = 0xff00ff00; else offset++;
                    if (++x >= overlay.width) {x = 0; offset += dy}
                    if (word & 0x0008) buf[offset++] = 0xff00ff00; else offset++;
                    if (++x >= overlay.width) {x = 0; offset += dy}
                    if (word & 0x0010) buf[offset++] = 0xff00ff00; else offset++;
                    if (++x >= overlay.width) {x = 0; offset += dy}
                    if (word & 0x0020) buf[offset++] = 0xff00ff00; else offset++;
                    if (++x >= overlay.width) {x = 0; offset += dy}
                    if (word & 0x0040) buf[offset++] = 0xff00ff00; else offset++;
                    if (++x >= overlay.width) {x = 0; offset += dy}
                    if (word & 0x0080) buf[offset++] = 0xff00ff00; else offset++;
                    if (++x >= overlay.width) {x = 0; offset += dy}
                }
            }
            context.putImageData(createImageData(pixmap, cols, rows), 0, 0);
        } else {
            context.clearRect(0, 0, context.canvas.width, context.canvas.height);
            context.font = '1.6rem "Noto Sans"';
            context.textAlign = 'center';
            context.textBaseline = 'middle';
            context.fillStyle = '#0f72c3';
            context.fillText('Loading image ' + (this.index + 1) + ' ...', context.canvas.width / 2.0, context.canvas.height / 2.0);
        }
    }

    private async renderCanvas(): Promise<Canvas> {
        const canvas = createCanvas(this.img.cols, this.img.rows);
        const context = canvas.getContext('2d', {alpha: false});
        if (context == null) {
            throw 'hidden CONTEXT is null';
        }
        await this.realRender(context);
        return canvas;
    }

    private async renderExact(width: number, height: number): Promise<Canvas> {
        const dstCanvas = createCanvas(width, height);
        const context = dstCanvas.getContext('2d', {alpha: false});
        const srcCanvas = await this.renderCanvas();
        context.drawImage(srcCanvas, 0, 0, srcCanvas.width, srcCanvas.height, 0, 0, dstCanvas.width, dstCanvas.height);
        return dstCanvas;
    }

    public async renderSized({height, width} : {height?: number, width?:number}): Promise<Canvas> {
        if (height !== undefined && width !== undefined) {
            return await this.renderExact(width, height);
        } else if (height !== undefined) {
            return await this.renderExact(height * this.img.cols / this.img.rows, height);
        } else if (width !== undefined) {
            return await this.renderExact(width, width * this.img.rows / this.img.cols);
        } else {
            return await this.renderCanvas();
        }
    }

    public async renderThumbnail(): Promise<ImageData> {
        const dstCanvas = createCanvas(Math.floor(120 * this.img.cols / this.img.rows), 120);
        const context = dstCanvas.getContext('2d', {alpha: false});
        const srcCanvas = await this.renderCanvas();
        context.imageSmoothingEnabled = true;
        context.imageSmoothingQuality = 'high';
        context.drawImage(srcCanvas, 0, 0, srcCanvas.width, srcCanvas.height, 0, 0, dstCanvas.width, dstCanvas.height);
        return context.getImageData(0, 0, dstCanvas.width, dstCanvas.height);
    }

    public async animationFrame(context: CanvasRenderingContext2D, t: Transform): Promise<void> {
        context.fillStyle = 'black';
        context.fillRect(0, 0, context.canvas.width, context.canvas.height);
        if (this.canvas) {
            context.setTransform(t.s, t.r, -t.r, t.s, t.tx, t.ty);
            context.imageSmoothingEnabled = true;
            context.imageSmoothingQuality = 'high';
            context.drawImage(this.canvas, 0, 0);
        }
    }

    public async load(
        resources: {
            getImageBegin(): Promise<void>;
            getImageFrame(start: number, end: number): Promise<ArrayBuffer|undefined>;
            getImageEnd(): Promise<void>;
        },
        render: () => Promise<void>,
        progress: (p: number) => void,
    ): Promise<void> {
        let renderPromise = Promise.resolve();
        await resources.getImageBegin();
        let j = this.index;
        for (let i = 0; i < this.img.frameCount; ++i) {
            if (j >= this.img.frameCount) {
                j %= this.img.frameCount;
            }
            const frame = this.img.frames[j];
            if (!frame.data && frame.dbOffset != undefined) {
                frame.data = await resources.getImageFrame(frame.dbOffset, frame.dbOffset + frame.dataSize);
                const l = frame.rows * frame.cols * this.img.bytes * this.img.samplesPerPixel;
                if (frame.data?.byteLength !== l) {
                    console.error(`byteLength ${frame.data?.byteLength} <> ${l} expected length`);
                    //alertModal(`byteLength ${frame.data?.byteLength} <> ${l} expected length`);
                }
                const rect = frame.overlayRects[0];
                if (rect && frame.dbOffset != undefined && !frame.overlayBitmaps[0]) {
                    const start = frame.dbOffset + frame.dataSize;
                    frame.overlayBitmaps[0] = await resources.getImageFrame(start, start + rect.byteLength);
                }
            }
            if (j === this.index) {
                renderPromise = render();
            }
            progress(((i + 2) * 100.0) / (this.img.frameCount + 1));
            ++j;
        }
        await resources.getImageEnd();
        await renderPromise;
    }

    public convexMean({y0, y1, f}: {y0: number, y1: number, f: (y: number) => {x0: number, x1: number}}): {mean?: number, stddev?: number} {
        const frame = this.img.frames[this.index];
        const buffer = frame.data;
        if (buffer) {
            let data;
            if (this.img.samplesPerPixel === 1) {
                if (this.img.bytes === 1) {
                    if (this.img.signed) {
                        data = new Int8Array(buffer);
                    } else {
                        data = new Uint8Array(buffer);
                    }
                } else if (this.img.bytes === 2) {
                    if (this.img.signed) {
                        data = new Int16Array(buffer);
                    } else {
                        data = new Uint16Array(buffer);
                    }
                }
            }
            if (data) {
                let sum = 0;
                let count = 0;
                let sq_sum = 0;
                if (y1 < y0) {
                    [y0, y1] = [y1, y0];
                }
                y0 = Math.floor(y0 + 0.5);
                y1 = Math.floor(y1 + 0.5);
                for (let y = y0; y < y1; ++y) {
                    let {x0, x1} = f(y + 0.5);
                    if (x1 < x0) {
                        [x0, x1] = [x1, x0];
                    }
                    x0 = Math.floor(x0 + 0.5);
                    x1 = Math.floor(x1 + 0.5);
                    for (let x = x0; x < x1; ++x) {
                        const v = data[frame.cols * y + x];
                        sum += v;
                        sq_sum += v * v;
                        ++count;
                    }
                }
                const mean = sum / count;
                const stddev = Math.sqrt(sq_sum / count - mean * mean);
                return {mean, stddev};
            }
        }
        return {};
    }
}

export function isDicomImg(img: Img): img is DicomImg {
    return img.iType === IType.Dicom;
}

export function isDicomRenderer(renderer?: Renderer): renderer is DicomRenderer {
    return (renderer && isDicomImg(renderer.img)) ?? false;
}

registerRendererType({
    name: 'DicomRenderer',
    hasMime(mime: string): boolean {
        return mime === 'application/dicom';
    },
    async makeImg(id: string, buffer: ArrayBuffer): Promise<DicomImg> {
        return makeDicom(buffer, id);
    },
    async addImg(dicom: DicomImg, buffer: ArrayBuffer): Promise<DicomImg> {
        await addDicom(dicom, buffer);
        return dicom;
    },
    isThis(resource: Img) {
        return resource.iType === IType.Dicom;
    },
    makeRenderer(resource: DicomImg): Renderer {
        return new DicomRenderer(resource);
    }
});
